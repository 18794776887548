<template> 
	<div>
		<HeaderLogin></HeaderLogin>	
		<!-- Main content Start -->
		<div class="login">
			<div class="container-lg p-0 text-center parent-relative">
				<back-button></back-button>
				<h1 class="pt-3">{{ $t('Plan.title') }}</h1>
			</div>
		</div>
		<div class="container-lg">
			<div class="plan-txt p-3">{{ $t('Plan.description') }}</div>
			<div class="row" >
				<div class="col-12 col-sm-6 col-lg-3 my-2" :key="index" v-for="(item, index) in planList">
					<div v-if="index==3" class="copy">
						<div class="copy-white">{{ $t('Plan.copy10') }}</div>
						<img class="copy-img" src="assets/images/white_v.png" alt="...">
					</div>
					<div v-if="index==2" class="copy">
						<div class="copy-white">{{ $t('Plan.copy20') }}</div>
						<img class="copy-img" src="assets/images/white_v.png" alt="...">
					</div>
					<div v-if="index==1" class="copy">
						<div class="copy-standard">{{ $t('Plan.copy30') }}</div>
						<img class="copy-img" src="assets/images/standard_v.png" alt="...">
					</div>
					<div v-if="index==0" class="copy">
						<div class="copy-premium">{{ $t('Plan.copy40') }}</div>
						<img class="copy-img" src="assets/images/premium_v.png" alt="...">
					</div>
					<div class="card card-block">
						<img v-if="index==3" src="assets/images/plan-light.png" class="img-plan" alt="...">
						<img v-if="index==2" src="assets/images/plan-basic.png" class="img-plan" alt="...">
						<img v-if="index==1" src="assets/images/plan-standard.png" class="img-plan" alt="...">
						<img v-if="index==0" src="assets/images/plan-premium.png" class="img-plan" alt="...">
						<div class="ml-3 mt-2">{{ getPlanName(item.plan_id) }}</div>
						<div class="ml-3">¥{{ Number(item.month_fee).toLocaleString() }}<span class="unit">{{ $t('Plan.unit') }}</span></div>
						<ul class="mr-3">
							<li>
								{{ $t('Plan.trainings', {trainings: item.ticket/4}) }}<br>
								<span class="unit">{{ $t('Plan.tickets', {tickets: item.ticket}) }}</span>
							</li>
						</ul>
						<div v-if="loggedIn" class="plan-btn mr-3 mb-3">
							<b-button
								v-if="loggedUserPlan==''"
								squared
								variant="warning"
								class="main-button"
								:to="{ name: 'Payment', params: { id: item.plan_id } }">
								{{ $t('Plan.select') }}
							</b-button>
							<div
								v-else-if="item.plan_id==loggedUserPlan"
								class="current-plan">
								<img src="assets/images/booking-pictogram.png" alt="" class="current-plan-img mr-1">
								{{ $t('Plan.current') }}
							</div>
							<div
								v-else-if="currentPlan!=null&&currentPlan.future_plan_id==item.plan_id"
								class="current-plan">
								<img src="assets/images/booking-pictogram.png" alt="" class="current-plan-img mr-1">
								{{ $t('Plan.next') }}
							</div>
							<b-button
								v-else-if="item.plan_id!=loggedUserPlan&&isChangeable"
								squared variant="warning"
								class="main-button"
								@click="changePlan(item.plan_id)">
								{{ $t('Plan.select') }}
							</b-button>
							<div
								v-else
								class="current-plan">
							</div>
						</div>
		    		</div>
				</div>
	    	</div>
			<div v-if="!loggedIn" class="py-4 text-center">
				<b-button
					squared variant="warning"
					class="main-button"
					:to="{ name: 'Registration' }">
					{{ $t('Plan.register') }}
				</b-button>
			</div>
			<dl>
				<dt>{{ $t('Plan.newContract') }}</dt>
				<dd><span class="caution">{{ $t('Plan.newContract1') }}</span>{{ $t('Plan.newContract2') }}<br>{{ $t('Plan.newContract3') }}</dd>
			</dl>
			<div class="explanation">{{ $t('Plan.changePlan') }}</div>
			<div class="explanation" v-html="$t('Plan.changePlan1')"></div>
			<dl>
				<dt>{{ $t('Plan.cancelContract') }}</dt>
				<dd>{{ $t('Plan.cancelContract1') }}</dd>
				<dt>{{ $t('Plan.caution') }}</dt>
				<dd>{{ $t('Plan.caution1') }}</dd>
			</dl>
			<div class="explanation pb-4" v-html="$t('Plan.referTerms')"></div>
		</div>
		<div class="cancel-panel">
			<div v-if="contract!=null&&contract.contract_state=='CANCEL_ENABLED'&&isChangeable" class="container-lg py-4">
				<b-button
					squared
					class="gray-button"
					@click="cancelPlan">
					{{ $t('Plan.cancel') }}
				</b-button>
			</div>
			<div v-if="currentPlan!=null&&currentPlan.cancel_at_period_end" class="container-lg py-4">
				{{ $t('Plan.currentCancel') }}
			</div>
		</div>
		<!-- Main content End -->	
		<FooterContent></FooterContent>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import BackButton from '@/components/BackButton.vue'

export default {
	components: {
		HeaderLogin,
		FooterContent,
		BackButton,
	},
    data() {
		return {
	    	planList: [
				{
					plan_id: "40",
					month_fee: 26000,
					ticket: 32,
				},
				{
					plan_id: "30",
					month_fee: 15000,
					ticket: 16,
				},
				{
					plan_id: "20",
					month_fee: 8000,
					ticket: 8,
				},
				{
					plan_id: "10",
					month_fee: 5000,
					ticket: 4,
				},
			],
			currentPlan: null,
			contract: null,
		}
    },
	computed: {
		loggedIn: function() {
			return this.$store.state.loggedIn;
		},
		loggedUserPlan: function() {
			return this.$store.state.loggedUserPlan;
		},
		isChangeable: function () {
			return this.currentPlan!=null
				&& !this.currentPlan.cancel_at_period_end
				&& this.currentPlan.future_plan_id=='';
		},
	},
    mounted() {
		document.title = 'Plan'
		if (this.loggedIn) {
			let loader = this.$loading.show()	
			axios.get(EMSStyleAPIBaseURL + 'current-plan')
			.then(response => {
				this.currentPlan = response.data;
				axios.get(EMSStyleAPIBaseURL + 'contract')
				.then(response => {
					this.contract = response.data;
					loader.hide()
				});
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		}
    },
    methods: {
		getPlanName(id) {
			if (id==10) {
				return this.$t('general.planName10');
			} else if (id==20) {
				return this.$t('general.planName20');
			} else if (id==30) {
				return this.$t('general.planName30');
			} else if (id==40) {
				return this.$t('general.planName40');
			} else {
				return "";
			}
		},
		changePlan(plan_id) {
			let plan_name = this.getPlanName(plan_id);
			let confirmtext = this.$t('Plan.confirmationChangeMessage', { name: plan_name});
			if (this.loggedUserPlan < plan_id) {
				confirmtext += this.$t('Plan.confirmationChangePlusMessage');
			}
			this.$swal({
				text: confirmtext,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK'
			}).then((result) => {
				if (result.value) {
					let loader = this.$loading.show()
					var formData = JSON.stringify({			    
						plan_id: plan_id,
					});	    
					axios.put(EMSStyleAPIBaseURL + 'contract', formData, {
						headers: {
							"Content-Type": "application/json"
						}
					})
					.then(response => {
						//console.log(response.data)
						loader.hide()
						this.$swal({
							text: this.$t('Plan.doneChangeMessage', { name: plan_name}),
							iconColor: 'transparent',
							iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
						}).then(() => {
							this.$router.push({
								name: 'Account',
							});
						});
					})
					.catch((error) => {
						loader.hide()
						console.error(error)
						this.$swal({
							text: this.$t('Plan.errorChangeMessage'),
							icon: 'error',
						});
					});
				}
			});
		},
		cancelPlan() {
			this.$swal({
				text: this.$t('Plan.confirmationCancelMessage'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK'
			}).then((result) => {
				if (result.value) {
					let loader = this.$loading.show()
					axios.delete(EMSStyleAPIBaseURL + 'contract')
					.then(response => {
						//console.log(response.data)
						loader.hide()
						this.$swal({
							text: this.$t('Plan.doneCancelMessage'),
							iconColor: 'transparent',
							iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
						}).then(() => {
							this.$router.push({
								name: 'Account',
							});
						});
					})
					.catch((error) => {
						loader.hide()
						console.error(error)
						this.$swal({
							text: this.$t('Plan.errorCancelMessage'),
							icon: 'error',
						});
					});
				}
			});
		}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
.login {
	background-color: #f0f0f0;
	border-bottom:2px solid #ff3600;
}
.plan-txt {
	font-size: 0.8rem;
	text-align: center;
}
.img-plan{
	padding-top: 10px;
	max-width: 120px;
}
.unit {
	font-size: 0.8rem;
}
ul {
	font-size: 0.9rem;
}
.plan-btn {
	text-align: right;
}
.current-plan {
	font-size: 0.9rem;
	height: 35.2px;
}
@media (min-width: 992px) {
	.current-plan {
		height: 38.71px;
	}
}
.current-plan-img {
	height: 0.9rem;
}
dl {
    margin-bottom: 0;
	font-size: 0.8rem;
}
dd {
    margin-bottom: 1rem;
    margin-left: 0.8rem;
}
.caution {
	color: #ff0046;
	text-decoration: underline;
}
.explanation {
	font-size: 0.8rem;
}
.copy {
	font-size: 0.8rem;
	text-align: center;
}
.copy-white {
	background-color: white;
	color: black;
}
.copy-basic {
	background-color: #ff0046;
	color: white;
}
.copy-standard {
	background-color: #ff5100;
	color: white;
}
.copy-premium {
	background-color: #9f7707;
	color: white;
}
.copy-white, .copy-basic, .copy-standard, .copy-premium {
	height: 2.5rem;
	display:flex;
	justify-content: center;
	align-items: center;
	line-height: 1.1;
}
.copy-img {
	vertical-align: top;
}
.cancel-panel {
	background-color: #f0f0f0;
}
</style>