import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

window.axios = require("axios");
//window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//window.axios.defaults.headers.common["Content-Disposition"] = "application/x-www-form-urlencoded";
window.axios.defaults.headers.common["Content-Type"] = "application/json";
//window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.withCredentials = true;
window.EMSStyleAPIBaseURL = process.env.VUE_APP_API_BASE_URL;
window.EMSStyleAPIBaseURL2 = process.env.VUE_APP_API_BASE_URL2;

import VueSession from 'vue-session'
Vue.use(VueSession)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import axios from 'axios'
import VueAxios from 'vue-axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import moment from "moment"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PK
};

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(VueCtkDateTimePicker)
Vue.use(VueAxios, axios)
Vue.use(StripePlugin, options);
Vue.use(VueSweetalert2);
Vue.use(Loading);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: window.navigator.language,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: {
    // 翻訳用のjson
    ja : require('./lang/ja.json'),
    en : require('./lang/en.json'),
  }
});

Vue.prototype.moment = moment

Vue.filter('formatLessonDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD')
  }
});

Vue.filter('formatLessonDay', function(value) {
  if (value) {
    return moment(String(value)).format('ddd').toUpperCase()
  }
});

Vue.filter('formatLessonTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
});

Vue.filter('formatLessonYear', function(value) {
  if (value) {
    return moment(String(value)).format('Y')
  }
});

import { version } from '../package.json'
router.beforeEach((to, from, next) => {
  // バージョンチェック
  //axios.get(`./version.json?timestamp=${new Date().getTime()}`)
  axios.get('./version.json', {
    // query URL without using browser cache
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  })
  .then(res => {
    if (version != res.data.version) {
      if (store.state.reloadVersion != res.data.version) {
        store.commit("reload", res.data.version)
        location.reload()
      }
    }
  })
  .catch(error => {
    console.error(error)
  })

  // isMobile の場合
  if (to.matched.some(record => record.meta.isMobile)) {
    next();
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
  } else if (to.matched.some(record => !record.meta.isPublic) && !store.state.loggedIn) {
    next({ path: '/login', query: { redirect: to.fullPath }});
  // 自動ログイン
  } else if (to.matched.some(record => record.path != '/login') && Vue.$cookies.isKey('emstyleauto') && !store.state.loggedIn) {
    next({ path: '/login', query: { redirect: to.fullPath }});
  // isTrainer の場合、かつ、トレイナーとしてログインしていない場合
  } else if (to.matched.some(record => record.meta.isTrainer) && !store.state.isTrainer) {
    next({ path: '/' });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n: i18n,
  render: function (h) { return h(App) }
}).$mount('#app')

