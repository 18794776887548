<template> 
	<!-- Main content Start -->
	<div class="main">
	<LessonForm
		:lessonId="lessonId"
		@redirectToBook="redirectToBook"
	></LessonForm>
	</div>
	<!-- Main content End -->	
</template>

<script>

// @ is an alias to /src
import LessonForm from '@/components/LessonForm.vue'

export default {
	components: {
		LessonForm,
	},
	data() {
		return {
			lessonId: this.$route.params.id,
		}
	},
	mounted() {
		window.getSessionFromMobile = this.getSessionFromMobile
		document.title = 'Enter Lesson'
	},
	methods: {
		redirectToBook() {
			if (this.lessonId) {
				this.$router.push(`/mlesson-detail/${this.lessonId}`);
			} else {
				var userAgent = window.navigator.userAgent.toLowerCase(),
				safari = /safari/.test(userAgent),
				ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
				try {
					if (ios) {
						window.webkit.messageHandlers.redirectToBook.postMessage('clicked');
					} else {
						window.androidinfo.redirectToBook();
					}
				} catch (err) {
					console.log('The native context does not exist yet');
				}
			}
		},
		getSessionFromMobile(loggedSessionID, isTrainer, loggedUserPlan, loggedUserID) {
			this.$store.commit("set", {
				loggedSessionID: loggedSessionID,
				isTrainer: isTrainer,
				loggedUserPlan: loggedUserPlan,
				loggedUserID: loggedUserID,
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  min-height: 100vh;
}
</style>