<template>
   	<div class="card card-block h-100">
		<a href="#" @click="clickImage">
    		<img class="training-img" :src="item.picture_url" @error="noImage" alt="...">
		</a>
		<div class="card-body parent-relative">
			<div class="remain">
				<div class="remain-title" v-bind:class="{'remain-back-available':isAvailable, 'remain-back-full':!isAvailable}">{{ $t('LessonDetail.left') }}</div>
				<div class="remain-number" v-bind:class="{'remain-available':isAvailable, 'remain-full':!isAvailable}">{{ remainNumber }}</div>
			</div>
    		<div class="d-flex">
				<div class="p-date">
					<div class="d-flex flex-row">
						<div class="cartyear align-self-end mr-1">{{ item.from_date | formatLessonYear }}</div>
						<img src="assets/images/ticket.png" alt="ticket" width="72px" height="20px">
						<div class="tic-nu align-self-center">
							{{ item.ticket }}
						</div>
					</div>
					<div class="d-flex flex-row align-items-baseline">
						<div class="cartdate">{{ item.from_date | formatLessonDate }}&nbsp;</div>
						<div class="cartday">{{ item.from_date | formatLessonDay }}</div>
					</div>
	    			<div class="carttime">{{ item.from_date | formatLessonTime }}~{{ item.to_date | formatLessonTime }}</div>
				</div>
				<div class="dotline"></div>
				<div>
					<div class="p-rateing d-flex flex-row flex-wrap">
						<div class="d-flex flex-row">
							<div class="rate-title align-self-center">{{ $t('LessonDetail.difficulty') }}:</div>
							<ul class="rate-ul align-self-center">
								<li v-for="index in starCount" :value="index" class="rate-li">
									<img v-if="item.label[1] >= index" src="assets/images/star-on.png" alt="" width="15px" height="15px" />
									<img v-else src="assets/images/star-off.png" alt="" width="15px" height="15px"/>
								</li>
							</ul>
						</div>
						<div class="d-flex flex-row">
							<div class="rate-title align-self-center">{{ $t('LessonDetail.intensity') }}:</div>
							<ul class="rate-ul align-self-center">
								<li v-for="index in starCount" :value="index" class="rate-li">
									<img v-if="item.label[2] >= index" src="assets/images/fire-on.png" alt="" width="15px" height="15px" />
									<img v-else src="assets/images/fire-off.png" alt="" width="15px" height="15px" />
								</li>
							</ul>
						</div>
					</div>
					<div class="d-flex flex-row">
						<img src="assets/images/trainer-pictogram.png" alt="" width="20px" height="20px" />
    					<div class="rate-title align-self-center">{{ $t('LessonDetail.trainer') }}: {{ item.trainer_nickname }}</div>
					</div>
					<div class="training-title">{{ item.title }}</div>
				</div>
    		</div>
		</div>
   	</div>
</template>

<script>
export default {
	name: 'LessonCard',
	props: ['item'],
	data() {
		return {
			starCount: 5,
		}
	},
	computed: {
		remainNumber: function() {
			if (this.item.max_member == null && this.item.reservation_count == null) {
				return 0;
			} else if (this.item.reservation_count == null) {
				return this.item.max_member;
			} else {
				return this.item.max_member - this.item.reservation_count;
			}
		},
		isAvailable: function() {
			return this.remainNumber > 0;
		}
	},
	methods: {
		clickImage() {
			this.$emit('click', this.item.lesson_id);
		},
		noImage(element){
			element.target.src = 'assets/images/panel_cover_image_default_1.svg'
		},
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-body {
	padding: 0.3rem;
}
.training-img {
	width:100%;
	height:calc((100vw - 30px) * 9 / 16);
	object-fit: cover;
}
@media (min-width: 768px) {
	.training-img {
		height:calc(((100vw / 2) - 30px) * 9 / 16);
	}
}
@media (min-width: 1200px) {
	.training-img {
		height:calc(((100vw / 3) - 30px) * 9 / 16);
	}
}
.tic-nu {
	font-size: 0.7rem;
	color: white;
	margin-left: -16px;
}
.cartyear {
	font-size: 0.5rem;
}
.cartdate {
	font-size: 1.2rem;
}
.cartday {
	font-size: 0.9rem;
}
.carttime {
	font-size: 0.9rem;
}
.dotline
{
	border-left: 2px dotted black;
	margin-left: 15px;
	margin-right: 10px;
}
.rate-title {
	font-size: 0.6rem;
}
.rate-ul{
	display: flex;
	list-style: none;
	margin-bottom: 0;
	margin-left: -2px;
	margin-right: 3px;
	padding-inline-start: 0;
	flex-direction: row;
	position: relative;
	font-size: small;
	flex-wrap: nowrap;
}
.rate-li{
	margin-left: 0px;
	width:10px;
}
.training-title {
	font-size: 0.9rem;
}
.remain {
	position: absolute;
	top: -19px;
	right: 5px;
	z-index: 100;
	box-shadow: 0 2px 4px #cbcbcb;
	width: 35px;
	border-radius: 4px;
	text-align: center;
}
.remain-title {
	font-size: 0.6rem;
	color: white;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 4px 4px 0 0;
}
.remain-back-available {
	background-color: #ff5100;
}
.remain-back-full {
	background-color: #949494;
}
.remain-number {
	font-size: 1.2rem;
	background-color: white;
	border-radius: 0 0 4px 4px;
}
.remain-available {
	color: #ff5100;
}
.remain-full {
	color: #949494;
}
.p-rateing {
	margin-right: 40px;
}
</style>