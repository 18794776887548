<template> 
	<div>
		<HeaderLogin></HeaderLogin>	
		<!-- Main content Start -->
		<div class="login">
		<div class="container-lg text-center">
			<form class="py-4" @submit.prevent="submitLoginForm">
				<h1 class="py-3">{{ $t('Login.title') }}</h1>
				<b-form-input
					id="email"
					ref="email"
					v-model="login.email"
					type="email"
					size="sm"
					:placeholder="$t('Login.email')"
					required
					class="mt-1"
				></b-form-input>
				<b-form-input
					id="password"
					ref="password"
					v-model="login.password"
					type="password"
					size="sm"
					:placeholder="$t('Login.password')"
					required
					class="mt-1"
				></b-form-input>
				<div class="mt-3">
					<b-button type="submit" squared variant="warning" class="main-button">{{ $t('Login.login') }}</b-button>
				</div>
				<b-form-checkbox
					id="autologin"
					v-model="autologin"
					class="mt-1 col-form-label-sm"
				>
					{{ $t('Login.autologin') }}
				</b-form-checkbox>
				<router-link :to="{ name: 'Reset' }" class="small-text">
					{{ $t('Login.reset') }}
				</router-link>
				<div>
					<b-button squared variant="warning" class="yellow-button mt-5" @click="redirectToRegistration">{{ $t('Login.registration') }}</b-button>
				</div>
				<b-form-checkbox
					id="trainer"
					v-model="login.trainer"
					class="mt-1 mb-3 col-form-label-sm"
				>
					{{ $t('Login.trainer') }}
				</b-form-checkbox>
			</form>
		</div>
		</div>
        <!-- Main content End -->
		<FooterContent></FooterContent>		
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'

export default {
	components: {
		HeaderLogin,
		FooterContent
	},
	data() {
		return {
			login: {
				email: "",
				password: "",
				trainer: false,
			},
			autologin: false,
		}
	},
	beforeCreate () {
		if (this.$store.state.loggedIn) {
			this.$router.push({ name: 'Home' })
		}
	},
	mounted() {
		document.title = 'Login'
		// 自動ログイン
		if (this.$cookies.isKey('emstyleauto')) {
			const cookieitems = this.$cookies.get('emstyleauto').split("/t");
			this.login.email = cookieitems[0];
			this.login.password = cookieitems[1];
			this.login.trainer = cookieitems[2] === 'true';
			this.autologin = true;
			this.submitLoginForm();
		}
	},
	methods: {
		updateTrainerFlag(e) {
			if (e.target.checked) {
				this.login.trainer = true
			} else {
				this.login.trainer = false
			}
		},
		submitLoginForm: function (e) {
			let loader = this.$loading.show()
			var formData = JSON.stringify({
				loginId: this.login.email,
				password: this.login.password,
				isTrainer: this.login.trainer,
			});
			axios.post(EMSStyleAPIBaseURL + 'login', formData, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then(response => {
				//console.log(response.data)
				let loginData = response.data;
				loginData.isTrainer = this.login.trainer;
				this.$store.commit('login', loginData);

				// 自動ログイン
				if (this.autologin) {
					let trainer = 'false';
					if (this.login.trainer) {
						trainer = 'true';
					}
					const cookievalue = this.login.email + "/t" + this.login.password + "/t" + trainer;
					this.$cookies.set('emstyleauto', cookievalue, '30d');
				} else {
					if (this.$cookies.isKey('emstyleauto')) {
						this.$cookies.remove('emstyleauto');
					}
				}
				loader.hide()
				if(this.login.trainer) {
					if (this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect);
					} else {
						this.$router.push("/");
					}
				} else {
					this.getUserCurrentPlan()
				}
			})
			.catch((error) => {
				loader.hide()		
				console.error(error)
				this.$swal({
					text: this.$t('Login.errorMessage'),
					icon: 'error',
				});
			});
		},
		getUserCurrentPlan: function(e) {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'current-plan').then(response => {
				//console.log(response.data)
				this.$store.commit("setCurrentPlan", response.data);
				loader.hide()
				if (this.$route.query.redirect) {
					this.$router.push(this.$route.query.redirect);
				} else {
					this.$router.push("/");
				}
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
			});
		},
		redirectToRegistration() {
			this.$router.push({
				name: 'Registration',
			})
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
.login {
	background-color: #f0f0f0;
}
a {
	color: black;
	text-decoration: underline;
}
.small-text {
	font-size: 0.8rem;
}
form {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
</style>