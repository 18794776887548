<template> 
	<div>
		<HeaderPostLogin v-if="loggedIn"></HeaderPostLogin>	    
		<HeaderContent v-else></HeaderContent>
		<TabMenu v-if="loggedIn" :active="activeTab"></TabMenu>
		<!-- Main content Start -->
		<div class="container-lg p-0 parent-relative">
			<back-button isLessonDetail="true"></back-button>
			<LessonDetailContent
				:lessonId="lessonId"
				:isMobile="false"
				@redirectToLogin="redirectToLogin"
				@redirectToPlan="redirectToPlan"
				@redirectToBook="redirectToBook"
				@redirectToEditLesson="redirectToEditLesson"
			></LessonDetailContent>
		</div>
		<!-- Main content End -->
		<FooterContent></FooterContent>	
	</div>
</template>

<script>
// @ is an alias to /src
import HeaderPostLogin from '@/views/HeaderPostLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import HeaderContent from '@/views/HeaderContent.vue'
import LessonDetailContent from '@/components/LessonDetailContent'
import BackButton from '@/components/BackButton.vue'
import TabMenu from '@/components/TabMenu.vue'

export default {
	components: {
		HeaderPostLogin,
		HeaderContent,
		FooterContent,
		LessonDetailContent,
		BackButton,
		TabMenu,
	},
	data() {
		return {
			lessonId: this.$route.params.id,
		}
	},
	computed: {
		loggedIn: function() {
			return this.$store.state.loggedIn;
		},
		activeTab: function() {
			if (this.$store.state.backTo === 'Book') {
				return '3'
			} else {
				return '1'
			}
		},
	},
	mounted() {
		document.title = 'Lesson Detail'
		// urlが変わるため、暫定措置
		setTimeout(() => {
			window.location.hash = '#' + this.$route.fullPath;
		}, 1);
	},
	methods: {
		redirectToLogin() {
			this.$router.push({
				name: 'Login',
			})
		},
		redirectToPlan() {
			this.$router.push({
				name: 'Plan',
			})
		},
		redirectToBook() {
			this.$router.push({
				name: 'Book',
			})
		},
		redirectToEditLesson() {
			this.$router.push(`/edit-lesson/${this.lessonId}`);
		},
	}
}
</script>