<template> 
	<!-- Main content Start -->
	<div class="main container-fluid pt-3">
		<div class="row flex-row flex-nowrap overflow-auto pr-2" v-for="n in Math.ceil(lessonList.length / perRow)">
			<div class="col-11 col-md-5 col-xl-3 pb-3 pl-2 pr-0" :key="index" v-for="(item, index) in sliceList(n)">
				<lesson-card :item="item" @click="redirectToLessonDetail"></lesson-card>
			</div>
	    </div>
	</div>
	<!-- Main content End -->
</template>

<script>
// @ is an alias to /src
import LessonCard from '@/components/LessonCard.vue'
import axios from 'axios'
import moment from "moment"

export default {
	components: {
		LessonCard,
	},
	data() {
		return {
			lessonList: [],
			perRow: 4,
		}
	},
	mounted() {
	    window.getSessionFromMobile = this.getSessionFromMobile
	    document.title = 'Home'
		let loader = this.$loading.show()
		this.$store.commit('setBackTo', 'HomeMobile');
		axios.get(EMSStyleAPIBaseURL + 'lesson')
		.then(response => {
			let now = moment();
			this.lessonList = response.data
			.filter(item => {
				if (item.same_day_reservation_flg) {
					// 当日予約可の場合はレッスン開始日時から30分前が予約締め切り日時
					return moment(item.from_date + "Z").subtract(30, 'minutes').isAfter(now);
				} else {
					// 当日予約不可の場合はレッスン開始日時から24時間前が予約締め切り日時
					return moment(item.from_date + "Z").subtract(1, 'days').isAfter(now);
				}
			})
			.sort((a, b) => {
				//from_dateの昇順
				if(a.from_date > b.from_date) return 1;
				if(a.from_date < b.from_date) return -1;
				return 0;
			});
			this.lessonList.forEach(item => {
				item.from_date = item.from_date + "Z";
				item.to_date = item.to_date + "Z";
			});
			loader.hide()
		})
		.catch((error) => {
			loader.hide()
			console.error(error)
			this.$swal({
				text: this.$t('general.errorMessage'),
				icon: 'error',
			});
		});
	},
	methods: {
		sliceList(n) {
			return this.lessonList.slice((n-1)*this.perRow, n*this.perRow);
		},
	    redirectToLessonDetail(lessonId) {
			this.$router.push(`/mlesson-detail/${lessonId}`);
	    },
		getSessionFromMobile(loggedSessionID, isTrainer, loggedUserPlan, loggedUserID) {
			this.$store.commit("set", {
				loggedSessionID: loggedSessionID,
				isTrainer: isTrainer,
				loggedUserPlan: loggedUserPlan,
				loggedUserID: loggedUserID,
			});
	    }
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  min-height: 100vh;
}
</style>