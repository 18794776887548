<template> 
	<div>
		<HeaderPostLogin></HeaderPostLogin>		
		<TabMenu :active="2"></TabMenu>
		<!-- Main content Start -->
		<LessonForm
			v-if="isConfirmedTrainer"
			:lessonId="lessonId"
			@redirectToBook="redirectToBook"
		></LessonForm>
		<div v-if="!isConfirmedTrainer" class="text-center py-3">
			{{ $t('AddLesson.underReview') }}
		</div>
		<!-- Main content End -->	
		<FooterContent></FooterContent>
	</div>
</template>

<script>
// @ is an alias to /src
import HeaderPostLogin from '@/views/HeaderPostLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import TabMenu from '@/components/TabMenu.vue'
import LessonForm from '@/components/LessonForm.vue'

export default {
	components: {
		HeaderPostLogin,
		FooterContent,
		TabMenu,
		LessonForm,
	},
	data() {
		return {
			lessonId: this.$route.params.id,
		}
	},
	computed: {
		isConfirmedTrainer: function() {
			return this.$store.state.isConfirmedTrainer;
		},
	},
	mounted() {
		document.title = 'Enter Lesson'
	},
	methods: {
		redirectToBook() {
			if (this.lessonId) {
				this.$router.push(`/lesson-detail/${this.lessonId}`);
			} else {
				this.$router.push({
					name: 'Book',
				})
			}
		},
	}
}
</script>