<template>
	<div class="banner h-100" @click="toPlan">
		<div class="banner-inner h-100">
			<div class="banner-text" v-html="$t('BannerPlans.text')"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BannerPlans',
	data() {
		return {
		}
	},
	methods: {
		toPlan() {
			this.$router.push({ name: 'Plan' });
		},
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner {
	position: relative;
	text-align: center;
	cursor: pointer;
	background: -moz-linear-gradient(top left, #ff7b3f 30%, #eaaa51 50%, #f2748b 70%); 
	background: -webkit-linear-gradient(top left, #ff7b3f 30%, #eaaa51 50%, #f2748b 70%); 
	background: linear-gradient(to bottom right, #ff7b3f 30%, #eaaa51 50%, #f2748b 70%);
	padding: 6%;
}
.banner-inner {
	background-color: white;
	box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
	min-height: 200px;
}
.banner-text {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	color: #ff5100;
	font-size: 1.7rem;
}
</style>