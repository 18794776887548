<template> 
	<!-- Main content Start -->
	<div class="main container-lg p-0 parent-relative">
		<back-button isLessonDetail="true"></back-button>
	<LessonDetailContent
		:lessonId="lessonId"
		:isMobile="true"
		@redirectToLessonStart="redirectToLessonStart"
		@redirectToLogin="redirectToLogin"
		@redirectToBook="redirectToBook"
		@redirectToEditLesson="redirectToEditLesson"
	></LessonDetailContent>
	</div>
	<!-- Main content End -->
</template>

<script>
// @ is an alias to /src
import LessonDetailContent from '@/components/LessonDetailContent'
import BackButton from '@/components/BackButton.vue'

export default {
	components: {
		LessonDetailContent,
		BackButton,
	},
	data() {
	    return {
			lessonId: this.$route.params.id,
	    }
	},
	mounted() {
	    window.getSessionFromMobile = this.getSessionFromMobile
	    document.title = 'Lesson Detail'
		// urlが変わるため、暫定措置
		setTimeout(() => {
			window.location.hash = '#' + this.$route.fullPath;
		}, 1);
	},
	methods: {
		redirectToLessonStart(data) {
			var userAgent = window.navigator.userAgent.toLowerCase(),
			safari = /safari/.test( userAgent ),
			ios = /iphone|ipod|ipad|macintosh/.test( userAgent );
			try {
				if(ios) {
					window.webkit.messageHandlers.redirectToStart.postMessage(data);
				} else {
					window.androidinfo.redirectToStart(data);
				}
			} catch(err) {
				console.log('The native context does not exist yet (redirectToStart(' + data +'))');
			}
		},
	    redirectToLogin() {
			var userAgent = window.navigator.userAgent.toLowerCase(),
			safari = /safari/.test(userAgent),
			ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
			try {
				if (ios) {
					window.webkit.messageHandlers.redirectToLogin.postMessage('clicked');
				} else {
					window.androidinfo.redirectToLogin();
				}
			} catch (err) {
				console.log('The native context does not exist yet');
			}
	    },
	    redirectToBook() {
			var userAgent = window.navigator.userAgent.toLowerCase(),
			safari = /safari/.test(userAgent),
			ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
			try {
				if (ios) {
					window.webkit.messageHandlers.redirectToBook.postMessage();
				} else {
					window.androidinfo.redirectToBook();
				}
			} catch (err) {
				console.log('The native context does not exist yet');
			}
	    },
		redirectToEditLesson() {
			this.$router.push(`/medit-lesson/${this.lessonId}`);
		},
	    getSessionFromMobile(loggedSessionID, isTrainer, loggedUserPlan, loggedUserID) {
			this.$store.commit("set", {
				loggedSessionID: loggedSessionID,
				isTrainer: isTrainer,
				loggedUserPlan: loggedUserPlan,
				loggedUserID: loggedUserID,
			});
	    }
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  min-height: 100vh;
}
</style>