<template>
	<div>
		<HeaderLogin></HeaderLogin>	
		<!-- Main content Start -->
		<div class="login">
		<div class="container-lg text-center parent-relative">
			<back-button></back-button>
			<h1 class="pt-3">{{ $t('Account.title') }}</h1>
			<div class="d-flex flex-row align-items-center justify-content-center">
				<img v-if="loggedUserPlan==10" src="assets/images/plan-light.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==20" src="assets/images/plan-basic.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==30" src="assets/images/plan-standard.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==40" src="assets/images/plan-premium.png" class="img-plane1" alt="...">
				<div class="pro-fli mx-2">
					<UploadImageButton :url="userPictureUrl" circle="true" @change="processFile"></UploadImageButton>
				</div>
			</div>
			<form @submit.prevent="submitUserAccount">
				<table class="table wht mt-2" >
					<tbody>
						<tr>
							<th scope="row">{{ $t('Account.nickname') }}</th>
							<td>
								<b-form-input v-model="formData.nickname" size="sm" :placeholder="$t('Account.nickname')" required></b-form-input>
							</td>
							<td><b-button squared type="submit" class="gray-button">{{ $t('Account.save') }}</b-button></td>
						</tr>
						<tr>
							<th scope="row">{{ $t('Account.mail') }}</th>
							<td colspan="2">{{ this.loggedEmailID }}</td>
						</tr>
					</tbody>
				</table>
				</form>
			<div v-if="!isTrainer">
				<table class="table wht">
					<tbody>
						<tr class="clickable" @click="clickPlan">
							<th scope="row">{{ $t('Account.plan') }}</th>
							<td v-if="this.loggedUserPlanName">{{ this.loggedUserPlanName }}</td>
							<td v-else>{{ $t('Account.select') }}</td>
							<td class="w20">></td>
						</tr>
						<tr v-if="this.loggedUserPlanName">
							<th scope="row">{{ $t('Account.startDate') }}</th>
							<td colspan="2">{{ this.startDate }}</td>
						</tr>
						<tr v-if="this.loggedUserPlanName">
							<th scope="row">{{ $t('Account.tickets') }}</th>
							<td colspan="2">{{ loggedUserTicketRemaining }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="isTrainer">
				<table class="table wht">
					<tbody>
						<tr>
							<th scope="row">{{ $t('Account.organization') }}</th>
							<td>{{ this.$store.state.organization }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="fit-box">
				<b-button squared class="gray-button my-2" href="https://cloud.xenoma.com/account" target="_blank">{{ $t('Account.manage') }}</b-button>
				<div>
					<b-button squared class="gray-button mb-4 fit-width" @click="logout">{{ $t('Account.logout') }}</b-button>
				</div>
			</div>
		</div>
		</div>
		<!-- Main content End -->	
		<FooterContent></FooterContent>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import BackButton from '@/components/BackButton.vue'
import UploadImageButton from '@/components/UploadImageButton.vue'

export default {
	components: {
		HeaderLogin,
		FooterContent,
		BackButton,
		UploadImageButton,
	},
	data() {
		return {
			userData: {
				nickname: "",
				picture_url: "",
			},
			formData: {
				nickname: "",
				picture: "",
			},
			contract: null,
			loggedUserTicketRemaining: 0,
		}
	},
	computed: {
		loggedIn: function() {
			return this.$store.state.loggedIn;
		},
		loggedEmailID: function() {
			return this.$store.state.loggedEmailID;
		},
		isTrainer: function() {
			return this.$store.state.isTrainer;
		},
		loggedUserPlan: function() {
			return this.$store.state.loggedUserPlan;
		},
		loggedUserPlanName: function() {
			if (this.loggedUserPlan==10) {
				return this.$t('general.planName10');
			} else if (this.loggedUserPlan==20) {
				return this.$t('general.planName20');
			} else if (this.loggedUserPlan==30) {
				return this.$t('general.planName30');
			} else if (this.loggedUserPlan==40) {
				return this.$t('general.planName40');
			} else {
				return "";
			}
		},
		userPictureUrl: function() {
			if (this.userData.picture_url === "") {
				return 'assets/images/user-thumbnail.png';
			} else {
				return this.userData.picture_url;
			}
		},
		startDate: function () {
			if (this.contract == null) {
				return "";
			} else {
				return new Date(this.contract.billing_cycle_anchor * 1000).toLocaleDateString();
			}
		},
		isChanged: function() {
			return this.userData.nickname != this.formData.nickname || this.formData.picture != "";
		},
	},
	watch: {
		loggedUserPlan: function (val) {
			if (val != null && val != '') {
				this.getUserContract();
			}
		},
	},
	mounted() {
		document.title = 'Account'
		this.getUserProfile();
		if(!this.isTrainer) {
			this.getUserCurrentPlan()
			this.getUserTicketRemaining()
			this.getUserContract();
		}
	},
	methods: {
		processFile(picture, picture_url) {
			this.formData.picture = picture;
			this.userData.picture_url = picture_url;
		},
		clickPlan() {
			this.execAfterCheckChanged(() => {
				this.$router.push({ name: 'Plan' });
			})
		},
		logout() {
			this.execAfterCheckChanged(() => {
				this.$store.commit('logout');
				this.$cookies.remove("JSESSIONID");
				if (this.$cookies.isKey('emstyleauto')) {
					this.$cookies.remove('emstyleauto');
				}
				this.$router.push('/')
			})
		},
		execAfterCheckChanged(func) {
			if (this.isChanged) {
				this.$swal({
					text: this.$t('Account.confirmationMessage'),
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.value) {
						func();
					}
				})
			} else {
				func();
			}
		},
		submitUserAccount: function (e) {
			if (this.formData.nickname.length > 16) {
				this.$swal({
					text: this.$t('Account.errorNicknameMessage'),
					icon: 'error',
				});
				return;
			}
			let loader = this.$loading.show()
			axios.put(EMSStyleAPIBaseURL + 'profile', this.formData, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then(response => {
				//console.log(response.data)
				loader.hide();
				this.initFormData();
				this.getUserProfile();
				this.$swal({
					text: this.$t('Account.doneMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				});
			})
			.catch((error) => {
			    loader.hide();
			    console.error(error);
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		},
		initFormData() {
			this.formData.nickname = "";
			this.formData.picture = "";
		},
		getUserProfile() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'profile')
			.then(response => {
				this.userData = response.data
				this.formData.nickname = response.data.nickname
				this.$store.commit('setProfile', response.data);
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				this.logout();
				this.$swal({
					text: this.$t('Account.loginMessage'),
					icon: 'error',
				});
			});
		},
		getUserCurrentPlan: function(e) {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'current-plan')
			.then(response => {
				this.$store.commit("setCurrentPlan", response.data);
				loader.hide()
			})
			.catch((error) => {
			    loader.hide()
			    console.error(error)
			});
		},
		getUserTicketRemaining: function(e) {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'ticket')
			.then(response => {
				this.loggedUserTicketRemaining = response.data.available
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
			});
		},
		getUserContract() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'contract')
			.then(response => {
				this.contract = response.data;
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
			});
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
.login {
	background-color: #f0f0f0;
}
.clickable {
	cursor: pointer !important;
}
.img-plane1{
	max-width: 120px;
}
.pro-fli{
	width: 100px;
	height: 100px;
	--circle-height: 100px;
}
.table {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
.wht{
	background: white;
}
th,td {
	font-size: 0.8rem;
	text-align: left;
	vertical-align: middle !important;
}
.w20 {
	width: 20px;
}
.fit-box {
	display: inline-block;
}
.fit-width {
	width: 100%;
}
</style>
