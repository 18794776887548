<template> 
	<div>
		<HeaderLogin></HeaderLogin>	
		<!-- Main content Start -->
		<div class="login">
		<div class="container-lg text-center parent-relative">
			<back-button></back-button>
			<form class="py-4" @submit.prevent="submitRegistration">
				<h1 class="py-3">
					<span v-if="isTrainer">{{ $t('Registration.trainerTitle') }}<br></span>
					{{ $t('Registration.title') }}
				</h1>
				<div v-if="!isTrainer" class="small-text pb-3">
					{{ $t('Registration.description') }}<br>
					<router-link :to="{ name: 'Login' }">
						{{ $t('Registration.login') }}
					</router-link>
				</div>
				<b-form-input
					id="email"
					ref="email"
					v-model="email"
					type="email"
					size="sm"
					:placeholder="$t('Registration.email')"
					required
					class="mt-1"
				></b-form-input>
				<b-form-input
					id="password"
					ref="password"
					v-model="password"
					type="password"
					size="sm"
					:placeholder="$t('Registration.password')"
					required
					class="mt-1"
				></b-form-input>
				<b-form-input
					v-if="isTrainer"
					id="userName"
					ref="userName"
					v-model="userName"
					type="text"
					size="sm"
					:placeholder="$t('Registration.userName')"
					required
					class="mt-1"
				></b-form-input>
				<b-form-input
					v-if="isTrainer"
					id="organization"
					ref="organization"
					v-model="organization"
					type="text"
					size="sm"
					:placeholder="$t('Registration.organization')"
					class="mt-1"
				></b-form-input>
				<div class="mt-3 small-text">
					<a :href="$t('footer.termsOfServiceUrl')" target="_blank">{{ $t('Registration.termsOfService') }}</a>
				</div>
				<div class="mt-1 small-text">
					<a :href="$t('footer.privacyUrl')" target="_blank">{{ $t('Registration.privacy') }}</a>
				</div>
				<b-form-checkbox
					id="agree"
					v-model="agree"
					class="mt-1 col-form-label-sm"
				>
					{{ $t('Registration.agree') }}
				</b-form-checkbox>
				<b-button :disabled="!agree" type="submit" squared variant="warning" class="yellow-button mt-5 mb-3">{{ $t('Registration.registration') }}</b-button>
				<div v-if="!isTrainer" @click="isTrainer=true" class="small-text">{{ $t('Registration.toTrainer') }}</div>
			</form>
		</div>
		</div>
		<!-- Main content End -->		
		<FooterContent></FooterContent>		
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import BackButton from '@/components/BackButton.vue'

export default {
	components: {
		HeaderLogin,
		FooterContent,
		BackButton,
	},
	data() {
		return {
			email: '',
			password: '',
			agree: false,
			userName: '',
			organization: '',
			isTrainer: false,
			errors: {            
				email: null,
				password: null,            
				agree: null,  
			},
		}
	},
	computed: {
		isPasswordValid: function() {
			if (this.password == null) {
				return false;
			} else if (this.password.length < 8) {
				return false;
			} else {
				return String(this.password).match(/[A-Z]/)!=null && String(this.password).match(/[a-z]/)!=null && String(this.password).match(/[0-9]/)!=null;
			}
		},
		isUserNameValid: function() {
			if (!this.isTrainer) {
				return true;
			} else if (this.userName == null) {
				return false;
			} else if (this.userName.length === 0 || this.userName.length > 16) {
				return false;
			} else {
				return true;
			}
		},
		isOrganizationValid: function() {
			if (!this.isTrainer) {
				return true;
			} else if (this.organization == null) {
				return false;
			} else if (this.organization.length > 255) {
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		document.title = 'Registration'
	},
	methods: {
		submitRegistration: function (e) {
			if (!this.isPasswordValid) {
				this.$refs.password.focus();
				this.$swal({
					text: this.$t('Registration.passwordErrorMessage'),
					icon: 'error',
				});
				return;
			}
			if (!this.isUserNameValid) {
				this.$refs.userName.focus();
				this.$swal({
					text: this.$t('Registration.userNameErrorMessage'),
					icon: 'error',
				});
				return;
			}
			if (!this.isOrganizationValid) {
				this.$refs.organization.focus();
				this.$swal({
					text: this.$t('Registration.organizationErrorMessage'),
					icon: 'error',
				});
				return;
			}
			let loader = this.$loading.show()
			if (!this.isTrainer) {
				this.userName = this.email.substring(0, Math.min(16, this.email.indexOf('@')));
			}
			let params = JSON.stringify({
				email: this.email,
				password: this.password,
				userName: this.userName,
				organization: this.organization,
				isTrainer: this.isTrainer,
			});
			axios.post(EMSStyleAPIBaseURL + 'pub/account-register', params, {
			    headers: {
				"Content-Type": "application/json"
			    }
			})
			.then(response => {
			    loader.hide()
				this.$swal({
					text: this.$t('Registration.doneMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				})
				.then(() => {
					this.$router.push({
						name: 'Login',
						query: { redirect: '/plan' },
					})
				});
			})
			.catch((error) => {
			    loader.hide()
			    console.error(error)
				this.$swal({
					text: this.$t('Registration.errorMessage'),
					icon: 'error',
				});
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
.login {
	background-color: #f0f0f0;
}
a {
	color: black;
	text-decoration: underline;
}
.small-text {
	font-size: 0.8rem;
}
form {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
</style>