<template> 
	<div>
		<div v-show="!isPreview" class="container-lg px-1 pt-2">
			<h1 v-if="isAdd">{{ $t('LessonForm.titleAdd') }}</h1>
			<h1 v-else>{{ $t('LessonForm.titleEdit') }}</h1>
			<form id="form" class="mx-3" @submit.prevent="submitLessonForm" enctype="multipart/form-data">
				<div class="row">
					<div class="col-12 col-lg-6">
						<b-form-group
							:label="$t('LessonForm.trainingType')"
							label-for="training_type"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-select
								id="training_type"
								ref="training_type"
								v-model="form.training_type"
								:options="trainingTypeOptionList"
								size="sm"
								required
							>
							</b-form-select>
						</b-form-group>
					</div>
					<div class="col-12 col-lg-6">
						<b-form-group
							:label="$t('LessonForm.title')"
							label-for="training_title"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-input
								id="training_title"
								ref="training_title"
								v-model="form.title"
								size="sm"
								:placeholder="$t('LessonForm.titlePlaceholder')"
								required
							></b-form-input>
						</b-form-group>
					</div>
					<div class="col-12">
						<UploadImageButton :url="form.picture_url" @change="processFile"></UploadImageButton>
					</div>
					<div class="col-12 col-lg-6">
						<b-form-group
							:label="$t('LessonForm.trainingDetails')"
							label-for="description"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-textarea
								id="description"
								ref="description"
								v-model="form.description"
								rows="4"
								size="sm"
							></b-form-textarea>
						</b-form-group>
					</div>
					<div class="col-12 col-lg-6">
						<b-form-group
							:label="$t('LessonForm.tags')"
							label-for="tags"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-textarea
								id="tags"
								ref="tags"
								v-model="form.tags"
								rows="4"
								size="sm"
							></b-form-textarea>
						</b-form-group>
					</div>
					<div class="col-6 col-lg-3">
						<b-form-group
							:label="$t('LessonForm.difficulty')"
							label-for="difficulty"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-select
								id="difficulty"
								ref="difficulty"
								v-model="form.difficulty"
								size="sm"
								required
							>
								<template #first>
									<option value="">{{ $t('LessonForm.select') }}</option>
								</template>
								<b-form-select-option v-for="index in selectOptionCount" :value="index">{{ index }}</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</div>
					<div class="col-6 col-lg-3">
						<b-form-group
							:label="$t('LessonForm.intensity')"
							label-for="intensity"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-select
								id="intensity"
								ref="intensity"
								v-model="form.intensity"
								size="sm"
								required
							>
								<template #first>
									<option value="">{{ $t('LessonForm.select') }}</option>
								</template>
								<b-form-select-option v-for="index in selectOptionCount" :value="index">{{ index }}</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</div>
					<div class="col-6 col-lg-3">
						<b-form-group
							:label="$t('LessonForm.maxParticipants')"
							label-for="max_member"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-select
								id="max_member"
								ref="max_member"
								v-model="form.max_member"
								size="sm"
								required
							>
								<b-form-select-option v-for="index in participantCount" :value="index">{{ index+$t('LessonForm.unit') }}</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</div>
					<div class="col-6 col-lg-3">
						<b-form-group
							:label="$t('LessonForm.language')"
							label-for="spoken_language"
							label-size="sm"
							label-class="mb-0"
							class="mb-1"
						>
							<b-form-select
								id="spoken_language"
								ref="spoken_language"
								v-model="form.spoken_language"
								:options="languageOptionList"
								size="sm"
								required
							>
								<template #first>
									<option value="">{{ $t('LessonForm.select') }}</option>
								</template>
							</b-form-select>
						</b-form-group>
					</div>
					<div class="col-12">
						<label for="things_to_bring" class="col-form-label-sm mb-0 pb-0">{{ $t('LessonForm.equipment') }}</label>
					</div>
					<div class="col-12 col-lg-3">
						<b-form-checkbox
							id="non_ems_flg"
							v-model="form.non_ems_flg"
							class="mb-1 col-form-label-sm"
						>
							{{ $t('LessonForm.noEms') }}
						</b-form-checkbox>
					</div>
					<div class="col-12 col-lg-9">
						<b-form-input
							id="things_to_bring"
							ref="things_to_bring"
							v-model="form.things_to_bring"
							size="sm"
							:placeholder="$t('LessonForm.equipmentPlaceholder')"
							required
						></b-form-input>
					</div>
					<div class="col-12">
						<div class="col-form-label-sm mb-0 pb-0">{{ $t('LessonForm.traningDate') }}</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="row align-items-center">
							<div class="col-3 text-right">
								<label class="col-form-label-sm">{{ $t('LessonForm.start') }}</label>
							</div>
							<div class="col-9">
								<VueCtkDateTimePicker ref="from_date" locale="en" format="YYYY-MM-DDTHH:mm:ss" formatted="YYYY/MM/DD ddd HH:mm" :minute-interval="10" label="" :noClearButton="true" v-model="form.from_date" required/>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="row align-items-center">
							<div class="col-3 text-right">
								<label class="col-form-label-sm">{{ $t('LessonForm.end') }}</label>
							</div>
							<div class="col-9">
								<VueCtkDateTimePicker ref="to_date" locale="en" format="YYYY-MM-DDTHH:mm:ss" formatted="YYYY/MM/DD ddd HH:mm" :minute-interval="10" label="" :noClearButton="true" v-model="form.to_date" required/>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="col-form-label-sm mb-0 pb-0">{{ $t('LessonForm.bookingPeriod') }}</div>
					</div>
					<div class="col-12 col-lg-6">
						<b-form-checkbox
							id="same_day_reservation_flg"
							v-model="form.same_day_reservation_flg"
							class="mb-1 col-form-label-sm"
						>
							{{ $t('LessonForm.sameDay') }}
						</b-form-checkbox>
					</div>
					<div class="col-12 col-lg-6">
						<div class="row align-items-center">
							<div class="col-3 text-right">
								<label class="col-form-label-sm">{{ $t('LessonForm.cutoff') }}</label>
							</div>
							<div class="col-9">
								<b-form-input
									id="cut_off_date"
									ref="cut_off_date"
									v-model="cut_off_date"
									size="sm"
									disabled
								></b-form-input>
							</div>
						</div>
					</div>
				</div>
				<div class="py-4 text-center">
					<b-button v-if="!isAdd" squared class="gray-button mr-4" @click="$router.back()">{{ $t('LessonForm.back') }}</b-button>
					<b-button v-if="isTrainer" type="submit" squared variant="warning" class="main-button">{{ $t('LessonForm.preview') }}</b-button>
				</div>
			</form>
		</div>
		<div v-show="isPreview" class="container-lg p-0">
			<LessonDetailView :lesson="lesson"></LessonDetailView>
			<div class="py-4 text-center">
				<b-button squared class="gray-button" @click="isPreview=false">{{ $t('LessonForm.back') }}</b-button>
				<b-button squared variant="warning" class="main-button ml-4" @click="clickSave">{{ $t('LessonForm.save') }}</b-button>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import moment from "moment"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import UploadImageButton from '@/components/UploadImageButton.vue'
import LessonDetailView from '@/components/LessonDetailView.vue'

export default {
	name: 'LessonForm',
	props: ['lessonId'],
	components: {
		VueCtkDateTimePicker,
		UploadImageButton,
		LessonDetailView,
	},
	data() {
		return {
			selectOptionCount: 5,
			form: {
				training_type: "10",
				title: "",
				picture: "",
				picture_url: "",
				description: "",
				currency: "JPY",
				tags: "",
				difficulty: "",
				intensity: "",
				from_date: "",
				to_date: "",
				spoken_language: "",
				things_to_bring: "",
				max_member: "1",
				non_ems_flg: "",
				same_day_reservation_flg: ""
			},
			trainingTypeOptionList: [
				{text: this.$t('LessonForm.trainingType10'), value: '10'},
			],
			languageOptionList: [
				{text: this.$t('LessonForm.ENG'), value: 'ENG'},
				{text: this.$t('LessonForm.JP'), value: 'JP'},
			],
			isPreview: false,
			userData: {},
		}
	},
	computed: {
		isTrainer: function() {
			return this.$store.state.isTrainer;
		},
		isAdd: function() {
			return this.lessonId==null;
		},
		participantCount: function() {
			if(this.form.training_type==10) {
				return 1;
			} else if(this.form.training_type==20) {
				return 4;
			} else if(this.form.training_type==30) {
				return 100;
			} else {
				return 1;
			}
		},
		cut_off_date: function() {
			if(this.form.from_date) {
				var beforeMinutes = moment(this.form.from_date).add(-30, 'minutes').format('YYYY/MM/DD ddd HH:mm');	
				var beforeDays = moment(this.form.from_date).add(-1, 'days').format('YYYY/MM/DD ddd HH:mm');
				return (this.form.same_day_reservation_flg) ? beforeMinutes : beforeDays;
			} else {
				return "";
			}
		},
		ticket: function() {
			if(this.form.training_type==10) {
				return 4;
			} else if(this.form.training_type==20) {
				return 2;
			} else if(this.form.training_type==30) {
				return 1;
			} else {
				return 0;
			}
		},
		lesson: function() {
			return {
				title: this.form.title,
				picture_url: this.form.picture_url,
				description: this.form.description,
				from_date: moment(this.form.from_date).toISOString(),
				to_date: moment(this.form.to_date).toISOString(),
				spoken_language: this.form.spoken_language,
				things_to_bring: this.form.things_to_bring,
				max_member: this.form.max_member,
				label: [this.form.tags, this.form.difficulty, this.form.intensity],
				ticket: this.ticket,
				reservation_count: 0,
				trainer_nickname: this.userData.nickname,
				trainer_picture_url: this.userData.picture_url,
				same_day_reservation_flg: this.same_day_reservation_flg,
			};
		}
	},
	mounted() {
		this.getProfile();
		if (!this.isAdd) {
			this.getLesson();
		}
	},
	methods: {
		getProfile() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'profile')
			.then(response => {
				this.userData = response.data
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		},
		getLesson() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'lesson/' + this.lessonId)
			.then(response => {
				this.form.training_type = response.data.training_type;
				this.form.title = response.data.title;
				this.form.picture = "";
				this.form.picture_url = response.data.picture_url;
				this.form.description = response.data.description;
				this.form.currency = response.data.currency;
				this.form.tags = response.data.label[0];
				this.form.difficulty = response.data.label[1];
				this.form.intensity = response.data.label[2];
				this.form.from_date = moment.utc(response.data.from_date).local().format("YYYY-MM-DDTHH:mm:ss");
				this.form.to_date = moment.utc(response.data.to_date).local().format("YYYY-MM-DDTHH:mm:ss");
				this.form.spoken_language = response.data.spoken_language;
				this.form.things_to_bring = response.data.things_to_bring;
				this.form.max_member = response.data.max_member;
				this.form.non_ems_flg = response.data.non_ems_flg;
				this.form.same_day_reservation_flg = response.data.same_day_reservation_flg;
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		},
		processFile(picture, picture_url) {
			this.form.picture = picture;
			this.form.picture_url = picture_url;
		},
		submitLessonForm: function (e) {
			if (this.form.from_date == "") {
				this.$swal({
					text: this.$t('LessonForm.errorStartDateMessage'),
					icon: 'error',
				});
			} else if (moment(this.cut_off_date, 'YYYY/MM/DD ddd HH:mm').isSameOrBefore(moment())) {
				this.$swal({
					text: this.$t('LessonForm.errorCutOffDateMessage'),
					icon: 'error',
				});
			} else if (this.form.to_date == "") {
				this.$swal({
					text: this.$t('LessonForm.errorEndDateMessage'),
					icon: 'error',
				});
			} else if (moment(this.form.from_date).isSameOrAfter(moment(this.form.to_date))) {
				this.$swal({
					text: this.$t('LessonForm.errorTrainingDateMessage'),
					icon: 'error',
				});
			} else {
				this.isPreview = true;
			}
		},
		clickSave() {
			if (!document.getElementById('form').checkValidity()) {
				this.isPreview = false;
				return;
			}
			
			let loader = this.$loading.show()
			let formData = {
				training_type: this.form.training_type,
				title: this.form.title,
				picture: this.form.picture,
				description: this.form.description,
				currency: "JPY",
				from_date: moment(this.form.from_date).toISOString(),
				to_date: moment(this.form.to_date).toISOString(),
				spoken_language: this.form.spoken_language,
				things_to_bring: this.form.things_to_bring,
				max_member: this.form.max_member,
				non_ems_flg: this.form.non_ems_flg,
				same_day_reservation_flg: this.form.same_day_reservation_flg,
				label: [this.form.tags, this.form.difficulty, this.form.intensity]
			};
			if (this.isAdd) {
				axios.post(EMSStyleAPIBaseURL + 'lesson', JSON.stringify(formData), {
					headers: {
						"Content-Type": "application/json"
					}
				})
				.then(response => {
					//console.log(response.data)	
					loader.hide()
					this.$swal({
						text: this.$t('LessonForm.doneAddMessage'),
						iconColor: 'transparent',
						iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
					});
					this.init();
					this.redirectToBook()
				})
				.catch((error) => {
					loader.hide()
					console.error(error)
					this.$swal({
						text: this.$t('general.errorMessage'),
						icon: 'error',
					});
				});
			} else {
				if (this.form.picture==="") {
					delete formData.picture;
				}
				axios.put(EMSStyleAPIBaseURL + 'lesson/' + this.lessonId, JSON.stringify(formData), {
					headers: {
						"Content-Type": "application/json"
					}
				})
				.then(response => {
					loader.hide()
					this.$swal({
						text: this.$t('LessonForm.doneEditMessage'),
						iconColor: 'transparent',
						iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
					});
					this.redirectToBook()
				})
				.catch((error) => {
					loader.hide()
					console.error(error)
					this.$swal({
						text: this.$t('general.errorMessage'),
						icon: 'error',
					});
				});
			}
		},
		init() {
			this.form.training_type = "10";
			this.form.title = "";
			this.form.picture = "";
			this.form.picture_url = "";
			this.form.description = "";
			this.form.currency = "JPY";
			this.form.tags = "";
			this.form.difficulty = "";
			this.form.intensity = "";
			this.form.from_date = "";
			this.form.to_date = "";
			this.form.spoken_language = "";
			this.form.things_to_bring = "";
			this.form.max_member = "1";
			this.form.non_ems_flg = "";
			this.form.same_day_reservation_flg = "";
			this.isPreview = false;
		},
		redirectToBook() {
			this.$emit('redirectToBook');
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
</style>
