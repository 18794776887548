<template>       
	<!--Full width header Start-->
	<header id="rs-header" class="container-lg">
		<!-- Menu Start -->
		<div class="d-flex flex-row align-items-center mb-2 pt-2">
			<div class="mr-auto">
				<router-link :to="{ name: 'Home' }">
					<img src="assets/images/header-logo.png" alt="" class="logotop"/>
				</router-link>
			</div>
			<div>
				<img v-if="loggedUserPlan==10" src="assets/images/plan-light.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==20" src="assets/images/plan-basic.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==30" src="assets/images/plan-standard.png" class="img-plane1" alt="...">
				<img v-if="loggedUserPlan==40" src="assets/images/plan-premium.png" class="img-plane1" alt="...">
			</div>
			<div class="ml-1">
				<router-link :to="{ name: 'Account' }">
					<img :src="loggedUserPictureUrl" @error="noImage" class="user-imgs" alt="...">
				</router-link>
			</div>
		</div>
		<!-- Menu End -->
	</header>
	<!--Full width header End-->
</template>

<script>
export default {
	data() {
		return {
		}
	},
	computed: {
		loggedUserPlan: function() {
			return this.$store.state.loggedUserPlan;
		},
		loggedUserPictureUrl: function() {
			if (this.$store.state.loggedUserPictureUrl === "") {
				return 'assets/images/user-thumbnail.png';
			} else {
				return this.$store.state.loggedUserPictureUrl;
			}
		},
	},
	methods: {
		noImage(element){
			element.target.src = 'assets/images/user-thumbnail.png'
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logotop{
	max-width: 150px;
}
.img-plane1{
	max-width: 60px;
}
.user-imgs {
	border-radius: 50%;
	width: 35px;
	height: 35px;
	object-fit: cover;
}
@media screen and (min-width:992px) {
	.logotop{
		max-width: 200px;
	}
	.img-plane1{
		max-width: 80px;
	}
	.user-imgs {
		width: 45px;
		height: 45px;
	}
}
</style>