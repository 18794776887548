<template>
    <div>
        <button v-bind:class="{'white':isLessonDetail, 'gray':!isLessonDetail}" @click="click">&lt;</button>
    </div>
</template>

<script>
export default {
	name: 'BackButton',
	props: ['isLessonDetail'],
	methods: {
		// ボタンクリック
		click() {
			if (this.isLessonDetail) {
				let backTo = this.$store.state.backTo;
				if (backTo == '') {
					this.$router.back();
				} else {
					this.$router.push({ name: backTo });
				}
			} else {
				this.$router.back();
			}
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 40px;
	height: 40px;
	background: transparent;
	border: 0;
	font-size: 1.5rem;
	text-align: center;
}
.gray {
	color: #949494;
}
.white {
	color: white;
}
</style>