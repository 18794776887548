<template>
	<div>
		<label class="button mb-0">
			<input type="file" accept="image/*" @change="processFile($event)">
            <div v-if="url">
				<img class="img-fluid" v-bind:class="{ 'circle': circle=='true' }" :src="url" @error="noImage" alt="Select Image" />
			</div>
			<div v-else class="no-image" :style="{ 'background-image': 'url(' + assetsSelectFile + ')' }">
				{{$t('UploadImageButton.title')}}
			</div>
		</label>
	</div>
</template>

<script>
export default {
	name: 'UploadImageButton',
	props: ['url', 'circle'],
	data() {
		return {
			assetsSelectFile: "./assets/images/select_file.png",
		}
	},
	methods: {
		processFile(event) {
			let file = event.target.files || (event.dataTransfer && event.dataTransfer.files);
			file = file[0];
			this.getBase64(file)
				.then(image => {

					// canvasに渡すためのImageElementを作成
					const originalImg = new Image()

					// src属性に、もともとの画像のBase64形式符号化済み文字列を代入
					originalImg.src = image

					// Imgの画像読み込みが完了したあとに、処理を実行
					originalImg.onload = () => {
						const resizedCanvas = this.createResizedCanvasElement(originalImg)
						const resizedBase64 = resizedCanvas.toDataURL(file.type)
						const picture = resizedBase64.split("base64,")[1];
						this.$emit('change', picture, resizedBase64);
					}

				})
				.catch(error => {
					console.error(error)
				})
		},
		getBase64 (file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = error => reject(error)
			})
		},
		// ImageElementを受け取り、それを元に新たな画像をcanvasに描画し直す関数
		createResizedCanvasElement (originalImg) {
			// 16:9
			let aspectWidth = 16.0
			let aspectHeight = 9.0
			if (this.circle) {
				// 1:1
				aspectWidth = 1.0
				aspectHeight = 1.0
			}
			let sWidth = originalImg.width
			let sHeight = originalImg.height
			if (sWidth > Math.round(sHeight / aspectHeight * aspectWidth)) {
				sWidth = Math.round(sHeight / aspectHeight * aspectWidth)
			} else {
				sHeight = Math.round(sWidth / aspectWidth * aspectHeight)
			}
			// center切り取り
			let sx = 0
			let sy = 0
			if (originalImg.width > sWidth) {
				sx = Math.round((originalImg.width - sWidth) / 2)
			}
			if (originalImg.height > sHeight) {
				sy = Math.round((originalImg.height - sHeight) / 2)
			}
			// 縮小
      		const MAX_HEIGHT = 333
			let dWidth = sWidth
			let dHeight = sHeight
			if (dHeight > MAX_HEIGHT) {
				dWidth = Math.round(MAX_HEIGHT / aspectHeight * aspectWidth)
				dHeight = MAX_HEIGHT
			}

			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			canvas.width = dWidth
			canvas.height = dHeight

			ctx.drawImage(originalImg, sx, sy, sWidth, sHeight, 0, 0, dWidth, dHeight)
			return canvas
		},
		noImage(element){
			element.target.src = 'assets/images/select_file.png'
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="file"] {
    display: none;
}
.button {
	cursor: pointer;
}
.circle {
	border-radius: 50%;
	object-fit: cover;
	width: var(--circle-height);
	height: var(--circle-height);
}
.no-image {
	display: inline-block;
	width: calc(100vw - 2.5rem);
	height:calc((100vw - 2.5rem) * 9 / 16);
	padding: 0.5rem;
	color: #cccccc;
	font-size: 0.8rem;
	background-color: white;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50px;
}
@media (min-width: 768px) {
	.no-image {
		background-size: auto;
	}
}
@media (min-width: 992px) {
	.no-image {
		width: calc(960px - 2.5rem);
		height:calc((960px - 2.5rem) * 9 / 16);
	}
}
@media (min-width: 1200px) {
	.no-image {
		width: calc(1140px - 2.5rem);
		height:calc((1140px - 2.5rem) * 9 / 16);
	}
}
</style>