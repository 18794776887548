<template> 
	<div class="card" v-bind:class="{ 'colorfilter-base': isFinished() }">
		<a href="#" @click="clickImage">
			<img 
				class="training-img" 
				 v-bind:class="{ 'colorfilter-image': isFinished() }"
				:src="item.picture_url" 
				@error="noImage" 
				alt="..."
			/>
		</a>
		<div class="card-body">
			<div class="d-flex">
				<div class="b-date">
					<div class="cartyear">{{ item.from_date | formatLessonYear }}</div>
					<div class="d-flex flex-row align-items-baseline">
						<div class="cartdate">{{ item.from_date | formatLessonDate }}&nbsp;</div>
						<div class="cartday">{{ item.from_date | formatLessonDay }}</div>
					</div>
					<div class="carttime">{{ item.from_date | formatLessonTime }}~{{ item.to_date | formatLessonTime }}</div>
				</div>
				<div class="dotline"></div>
				<div class="p-rateing w-100">
					<div class="d-flex flex-row">
						<img 
							v-bind:class="{ 'colorfilter-image': isFinished() }"
							src="assets/images/trainer-pictogram.png" 
							alt="trainer pictogram" 
							width="20px" 
							height="20px" 
						/>
						<div class="rate-title align-self-center">{{ $t('LessonDetail.trainer') }}: {{ item.trainer_nickname }}</div>
					</div>
					<div class="d-flex flex-row">
						<div class="training-title">{{ item.title }}</div>
						<a href="#" class="align-self-end ml-auto" @click="clickImage">
							<img 
								v-bind:class="{ 'colorfilter-image': isFinished() }"
								:src="'assets/images/'+$t('LessonDetail.detailButtonImage')"
								alt="detail button" 
								width="48px" 
								height="32px" 
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import moment from "moment"

export default {
	name: 'BookCard',
	props: ['item'],
	data() {
		return {
		}
	},
	methods: {
		isFinished() {
			return moment(this.item.to_date).isBefore(moment());
		},
		clickImage() {
			this.$emit('click', this.item.lesson_id);
		},
		noImage(element){
			element.target.src = 'assets/images/panel_cover_image_default_1.svg'
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-body {
	padding: 0.3rem;
	background-color: #f0f0f0;
}
.training-img {
	width:100%;
	height:calc((100vw - 30px) * 9 / 16);
	object-fit: cover;
}
@media (min-width: 768px) {
	.training-img {
		height:calc(((100vw / 2) - 30px) * 9 / 16);
	}
}
@media (min-width: 992px) {
	.training-img {
		height:calc(((100vw / 3) - 30px) * 9 / 16);
	}
}
.cartyear {
	font-size: 0.5rem;
}
.cartdate {
	font-size: 1.2rem;
}
.cartday {
	font-size: 0.9rem;
}
.carttime {
	font-size: 0.9rem;
}
.dotline
{
	border-left: 2px dotted black;
	margin-left: 15px;
	margin-right: 10px;
}
.rate-title {
	font-size: 0.6rem;
}
.training-title {
	font-size: 0.9rem;
}
.colorfilter-base {
    background-color: #b1b1b1; /* カラーフィルタ効果の色を指定 */
	color: #949494;
}
.colorfilter-image {
    opacity: 0.6;    /* カラーフィルタ効果の度合いを指定(※) */
    display: block;
}
</style>