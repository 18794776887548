import Vue from 'vue'
import Vuex from 'vuex'

//import HomeIndex from './modules/Home'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {
    loggedIn: false,
    loggedUserID: '',
    loggedUserPictureUrl: '',
    loggedEmailID: '',
    isTrainer: false,
    isConfirmedTrainer: false,
    organization: '',
    loggedUserPlan: '',
    setFromMobile: false,
    backTo: '',
    bookCurrentPage: 1,
    reloadVersion: '',
  },
  mutations: {
    set(state, payload) {
      if (payload.loggedSessionID) {
        state.loggedIn = true;
      } else {
        state.loggedIn = false;
      }
      state.isTrainer = payload.isTrainer == 'true';
      state.isConfirmedTrainer = state.isTrainer;
      state.loggedUserPlan = payload.loggedUserPlan;
      state.loggedUserID = payload.loggedUserID;
      state.setFromMobile = true;
    },
    login(state, login) {
      state.loggedIn = true;
      state.loggedUserID = login.userId;
      state.loggedUserPictureUrl = login.pictureUrl;
      state.loggedEmailID = login.email;
      state.isTrainer = login.isTrainer;
      state.isConfirmedTrainer = login.roles.find(role => role === 'TRAINER') != null;
      state.organization = login.organization;
    },
    setCurrentPlan(state, currentPlan) {
      state.loggedUserPlan = currentPlan.current_plan_id;
    },
    setProfile(state, profile) {
      state.loggedUserPictureUrl = profile.picture_url;
    },
    logout(state) {
      state.loggedIn = false;
      state.loggedUserID = '';
      state.loggedUserPictureUrl = '';
      state.loggedEmailID = '';
      state.isTrainer = false;
      state.isConfirmedTrainer = false;
      state.organization = '';
      state.loggedUserPlan = '';
      state.setFromMobile = false;
      state.backTo = '';
      state.bookCurrentPage = 1;
    },
    setBackTo(state, componentName) {
      state.backTo = componentName;
    },
    clearBackTo(state) {
      state.backTo = '';
    },
    setBookCurrentPage(state, page) {
      state.bookCurrentPage = page;
    },
    reload(state, version) {
      state.reloadVersion = version;
    },
  },
  actions: {
      
  },
  modules: {
//      HomeIndex
  },
  strict: debug,
})
