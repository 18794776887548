<template> 
	<div>
		<HeaderLogin></HeaderLogin>	
		<!-- Main content Start -->
		<div class="login">
		<div class="container-lg text-center parent-relative">
			<back-button></back-button>
			<form class="py-4" @submit.prevent="submitReset">
				<h1 class="py-3">{{ $t('Reset.title') }}</h1>
				<b-form-input
					id="email"
					ref="email"
					v-model="email"
					type="email"
					size="sm"
					:placeholder="$t('Reset.email')"
					required
					class="mt-1"
				></b-form-input>
				<div class="mt-3 small-text">
					{{ $t('Reset.sendMail') }}
				</div>
				<b-button type="submit" squared variant="warning" class="yellow-button mt-5 mb-3">{{ $t('Reset.reset') }}</b-button>
			</form>
		</div>
		</div>
		<!-- Main content End -->		
		<FooterContent></FooterContent>		
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import BackButton from '@/components/BackButton.vue'

export default {
	components: {
		HeaderLogin,
		FooterContent,
		BackButton,
	},
	data() {
		return {
			email: '',
		}
	},
	mounted() {
		document.title = 'Password Reset'
	},
	methods: {
		submitReset: function (e) {
			let loader = this.$loading.show()
			let params = JSON.stringify({
				email: this.email,
			});
			axios.post(EMSStyleAPIBaseURL + 'pub/password-reset', params, {
			    headers: {
				"Content-Type": "application/json"
			    }
			})
			.then(response => {
			    loader.hide()
				this.$swal({
					text: this.$t('Reset.doneMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				});
			})
			.catch((error) => {
			    loader.hide()
			    console.error(error)
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	font-size: 1.3rem;
	font-weight: 900;
}
.login {
	background-color: #f0f0f0;
}
.small-text {
	font-size: 0.8rem;
}
form {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
</style>