<template> 
	<div>
		<div>
			<img :src="lesson.picture_url" @error="noImage" class="training-img" alt="Training Image">
		</div>
		<div class="wht p-1">
			<div class="d-flex flex-row align-items-center">
				<img :src="lesson.trainer_picture_url" @error="noTrainerImage" class="user-imgs" alt="Trainer Picture">
				<img src="assets/images/ticket.png" alt="ticket" class="ml-1" width="72px" height="20px">
				<div class="tic-nu">
					{{ lesson.ticket }}
				</div>
				<div class="d-flex flex-row flex-wrap ml-3">
					<div class="d-flex flex-row align-items-center">
						<div class="rate-title">{{ $t('LessonDetail.difficulty') }}:</div>
						<ul class="rate-ul">
							<li v-for="index in starCount" :value="index" class="rate-li">
								<img v-if="difficulty >= index" src="assets/images/star-on.png" alt="" width="15px" height="15px" />
								<img v-else src="assets/images/star-off.png" alt="" width="15px" height="15px"/>
							</li>
						</ul>
					</div>
					<div class="d-flex flex-row align-items-center">
						<div class="rate-title">{{ $t('LessonDetail.intensity') }}:</div>
						<ul class="rate-ul">
							<li v-for="index in starCount" :value="index" class="rate-li">
								<img v-if="intensity >= index" src="assets/images/fire-on.png" alt="" width="15px" height="15px" />
								<img v-else src="assets/images/fire-off.png" alt="" width="15px" height="15px" />
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="d-flex flex-row align-items-center">
				<img src="assets/images/trainer-pictogram.png" alt="" width="20px" height="20px" />
				<div class="rate-title">{{ $t('LessonDetail.trainer') }}: {{ lesson.trainer_nickname }}</div>
			</div>
			<div class="d-flex flex-row align-items-center">
				<img src="assets/images/equipment-pictogram.png" alt="" width="20px" height="20px" />
				<div class="rate-title">{{ $t('LessonDetail.equipment') }}: {{ lesson.things_to_bring }}</div>
				<img src="assets/images/language-pictogram.png" alt="" class="ml-1" width="20px" height="20px" />
				<div class="rate-title">{{ $t('LessonDetail.language') }}: {{ languageName }}</div>
				<img src="assets/images/booking-pictogram.png" alt="" class="ml-1" width="20px" height="20px" />
				<div class="rate-title">{{ $t('LessonDetail.reservation') }}: </div>
				<div class="remain mx-1">{{ $t('LessonDetail.left') }} </div>
				<div class="remain-num">{{ remainNumber }}</div>
			</div>
			<div class="d-flex flex-row align-items-baseline">
               	<div class="year align-self-start" >{{ lesson.from_date | formatLessonYear }}</div>
               	<div class="date ml-1">{{ lesson.from_date | formatLessonDate }}</div>
				<div class="day ml-1">{{ lesson.from_date | formatLessonDay }}</div>
               	<div class="time ml-1">{{ lesson.from_date | formatLessonTime }}~{{ lesson.to_date | formatLessonTime }}</div>
           	</div>
			<div class="training-title">{{ lesson.title }}</div>
		</div>
		<div class="training-desc p-1" v-html="descriptionHtml"></div>
		<div v-if="tags.length>0&&tags[0]!=''" class="d-flex flex-row m-1">
			<div class="book-view-btn ml-1 mt-1 px-1" v-for="(item, index) in tags" :key="index">{{ item }}</div>
		</div>            
		<div class="cutoffdate p-1">
			{{ $t('LessonDetail.cutoffdate') }}: {{ cut_off_date }}
		</div>
	</div>
</template>

<script>
import moment from "moment"

export default {
	name: 'LessonDetailView',
	props: {
		lesson: {
			title: String,
			picture_url: String,
			description: String,
			from_date: String,
			to_date: String,
			spoken_language: String,
			things_to_bring: String,
			max_member: Number,
			label: Array,
			ticket: Number,
			reservation_count: Number,
			trainer_nickname: String,
			trainer_picture_url: String,
			same_day_reservation_flg: Boolean,
		},
	},
	components: {
	},
	data() {
	    return {
			starCount: 5,
	    }
	},
	computed: {
		cut_off_date: function() {
			if (this.lesson.same_day_reservation_flg) {
				// 当日予約可の場合はレッスン開始日時から30分前がキャンセル締め切り日時
				return moment(this.lesson.from_date).subtract(30, 'minutes').format('YYYY/MM/DD ddd HH:mm');
			} else {
				// 当日予約不可の場合はレッスン開始日時から24時間前がキャンセル締め切り日時
				return moment(this.lesson.from_date).subtract(1, 'days').format('YYYY/MM/DD ddd HH:mm');
			}
		},
		descriptionHtml: function() {
			if (this.lesson.description == null) {
				return "";
			} else {
				return this.textToHtml(this.lesson.description);
			}
		},
		tags: function() {
			if (this.lesson.label != null && this.lesson.label.length > 0) {
				return this.lesson.label[0].split(" ");
			} else {
				return [];
			}
		},
		difficulty: function() {
			if (this.lesson.label != null && this.lesson.label.length > 1) {
				return this.lesson.label[1];
			} else {
				return "";
			}
		},
		intensity: function() {
			if (this.lesson.label != null && this.lesson.label.length > 2) {
				return this.lesson.label[2];
			} else {
				return "";
			}
		},
		languageName: function() {
			if (this.lesson.spoken_language == null) {
				return "";
			} else {
				if (this.lesson.spoken_language === 'JP') {
					return this.$t('LessonDetail.JP');
				} else if (this.lesson.spoken_language === 'ENG') {
					return this.$t('LessonDetail.ENG');
				} else {
					return this.lesson.spoken_language;
				}
			}
		},
		remainNumber: function() {
			if (this.lesson.max_member == null && this.lesson.reservation_count == null) {
				return 0;
			} else if (this.lesson.reservation_count == null) {
				return this.lesson.max_member;
			} else {
				return this.lesson.max_member - this.lesson.reservation_count;
			}
		}
	},
	methods: {
		// 本文整形
		textToHtml: function (text) {
			return this.htmlspecialchars(text)
			.replace(/\n/g, '<br>');
		},
		// 特殊文字を HTML エンティティに変換する
		htmlspecialchars: function (str){
			return String(str).replace(/&/g,"&amp;")
			.replace(/"/g,"&quot;")
			.replace(/</g,"&lt;")
			.replace(/>/g,"&gt;");
		},
		noImage(element){
			element.target.src = 'assets/images/panel_cover_image_default_1.svg'
		},
		noTrainerImage(element){
			element.target.src = 'assets/images/trainer_icon.png'
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.user-imgs {
	border-radius: 50%;
	object-fit: cover;
	width: 60px;
	height: 60px;
	margin-top: -45px;
}
@media (min-width: 768px) {
	.user-imgs {
		width: 100px;
		height: 100px;
		margin-top: -85px;
	}
}
.training-img {
	width: 100%;
	height: auto;
}
.wht{
	background: white;
}
.tic-nu {
	font-size: 0.7rem;
	color: white;
	margin-left: -16px;
}
.rate-title {
	font-size: 0.6rem;
}
.rate-ul{
	display: flex;
	list-style: none;
	margin-bottom: 0;
	margin-left: -2px;
	margin-right: 3px;
	padding-inline-start: 0;
	flex-direction: row;
	position: relative;
	font-size: small;
	flex-wrap: nowrap;
}
.rate-li{
	margin-left: 0px;
	width:10px;
}
.remain {
	color: #ff5100;
	font-size: 0.6rem;
}
.remain-num {
	color: #ff5100;
	font-size: 1.2rem;
}
.year {
	font-size: 0.6rem;
}
.date {
	font-size: 1.2rem;
}
.day {
	font-size: 0.9rem;
}
.time {
	font-size: 0.9rem;
}
.training-title {
	font-size: 0.9rem;
}
.training-desc {
	font-size: 0.8rem;
}
.book-view-btn {
	border: 1px solid #949494;
	background: white;
	border-radius:10%;
	font-size: 0.4rem;
}
.cutoffdate {
	font-size: 0.7rem;
}
</style>
