<template> 
	<div>
		<HeaderLogin></HeaderLogin>	
		<div class="dis-log">
			<div class="container-lg parent-relative">
				<back-button></back-button>
	    		<div class="payment-page">
					<div class="image-wrapper">
						<img v-if="plan_id==10" src="assets/images/plan-light.png" class="img-plane1" alt="...">
						<img v-if="plan_id==20" src="assets/images/plan-basic.png" class="img-plane1" alt="...">
						<img v-if="plan_id==30" src="assets/images/plan-standard.png" class="img-plane1" alt="...">
						<img v-if="plan_id==40" src="assets/images/plan-premium.png" class="img-plane1" alt="...">
					</div>
					<div class="plan-name text-center">{{ getPlanName(plan_id) }}</div>
					<div class="amount text-center">{{ $t('Payment.fee', { fee: Number(plan.month_fee).toLocaleString() }) }}</div>
					<div class="text-left mt-3">
						<form class="form-block form-center" @submit.prevent="pay">
							<div class="form-group">
								<label>{{ $t('Payment.card') }}</label>
								<div id="card-number" class="form-control card-number-data mb-10 input"></div>
								<div id="card-expiry" class="form-control expiry-data mb-10 input"></div>
								<div id="card-cvc" class="form-control cvv-data mb-10 input"></div>
								<div id="card-error"></div>
							</div>
							<div class="form-group">
								<label>{{ $t('Payment.country') }}</label>
								<select class="form-control" v-model="country" required="">
									<option value="">Select</option>
									<option value="JP">{{ $t('Payment.JP') }}</option>
								</select>
								<input type="text" inputmode="numeric" maxlength="10" v-model="postal_code" class="form-control" required="" :placeholder="$t('Payment.postalcode')"/>
							</div>
							<div class="form-group">
								<label>{{ $t('Payment.inviteCode') }}</label>
								<input type="text" inputmode="email" maxlength="50" v-model="invite_code" class="form-control" :placeholder="$t('Payment.inviteCodePlaceholder')"/>
							</div>
							<div class="form-group">
								<button id="custom-button" type="submit" class="btn btn-primary">{{ $t('Payment.pay') }}</button>
							</div>
							<div class="powered-by text-center mt-5 pb-3">Powered by <span class="stripe">stripe</span></div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<FooterContent></FooterContent>	
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HeaderLogin from '@/views/HeaderLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import BackButton from '@/components/BackButton.vue'

export default {
    components: {
	HeaderLogin,
	FooterContent,
		BackButton,
    },
    data () {
	return {
	    plan_id: this.$route.params.id,
	    country: "JP",
	    postal_code: "",
		invite_code: "",
	    token: null,
	    cardNumber: null,
	    cardExpiry: null,
	    cardCvc: null,
		plan: {
			plan_name: "",
			month_fee: 0,
		},
	};
    },
    computed: {
	stripeElements () {
	    return this.$stripe.elements();
	},
		lang: function () {
			if (this.$i18n.locale === 'ja') {
				return this.$i18n.locale;
			} else {
				return 'en';
			}
		},
    },
    mounted () {
	// Style Object documentation here: https://stripe.com/docs/js/appendix/style
	const style = {
	    base: {
	      color: 'black',
	      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
	      fontSmoothing: 'antialiased',
	      fontSize: '14px',
	      '::placeholder': {
		color: '#aab7c4',
	      },
	    },
	    invalid: {
	      color: '#fa755a',
	      iconColor: '#fa755a',
	    },
	};
	this.cardNumber = this.stripeElements.create('cardNumber', { style , showIcon: true});
	this.cardNumber.mount('#card-number');
	this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
	this.cardExpiry.mount('#card-expiry');
	this.cardCvc = this.stripeElements.create('cardCvc', { style });
	this.cardCvc.mount('#card-cvc');

		// 契約済みならBack
		if (this.$store.state.loggedUserPlan != '') {
			this.$router.back();
			return;
		}
		// プラン
		if (this.getPlanName(this.plan_id) == "") {
			this.$router.replace({ name: 'Plan' });
			return;
		}
		document.title = 'Payment'
		let loader = this.$loading.show()	
		axios.get(EMSStyleAPIBaseURL + 'plan')
		.then(response => {
			this.plan = response.data.find(item => item.plan_id === this.plan_id);
			loader.hide()
		})
		.catch((error) => {
			loader.hide()
			console.error(error)
			this.$swal({
				text: this.$t('general.errorMessage'),
				icon: 'error',
			});
		});
	},
    beforeDestroy () {
	this.cardNumber.destroy();
	this.cardExpiry.destroy();
	this.cardCvc.destroy();
    },
    methods: {
		getPlanName(id) {
			if (id==10) {
				return this.$t('general.planName10');
			} else if (id==20) {
				return this.$t('general.planName20');
			} else if (id==30) {
				return this.$t('general.planName30');
			} else if (id==40) {
				return this.$t('general.planName40');
			} else {
				return "";
			}
		},
		pay() {
			if (this.invite_code === "") {
				this.createToken();
			} else {
				let loader = this.$loading.show();
				// 招待コードの事前チェックAPI POST /invite_code
				var params = JSON.stringify({
					invite_code: this.invite_code,
				});
				axios.post(EMSStyleAPIBaseURL2 + 'invite_code', params, {
					headers: {
						"Content-Type": "application/json"
					}
				})
				.then((res) => {
					loader.hide()
					if (res.data.result === "OK") {
						this.createToken();
					} else {
						this.$swal({
							text: this.$t('Payment.errorInviteCodeMessage'),
							icon: 'error',
						});
					}
				})
				.catch((error) => {
					loader.hide()
					console.error(error)
					this.$swal({
						text: this.$t('general.errorMessage'),
						icon: 'error',
					});
				});
			}
		},
	async createToken () {
	    let loader = this.$loading.show()
	    const { token, error } = await this.$stripe.createToken(this.cardNumber);
	    if (error) {
	      // handle error here
	      document.getElementById('card-error').innerHTML = error.message;
	      loader.hide()
	      return;
	    }
	    //console.log(token);
	    // handle the token
	    // send it to your server
	    this.$stripe.createPaymentMethod({
		type: 'card',
		card: (this.cardNumber, this.cardExpiry, this.cardCvc),
		billing_details: {
		    // Include any additional collected billing details.
		    name: this.$store.state.loggedEmailID,
		    address: {
			country: this.country,
			postal_code: this.postal_code,
		    }
		},
	    }).then(result  => {
		if (result.error) {
		    document.getElementById('card-error').innerHTML = result.error.message;
		    loader.hide()
		} else {
		    // Otherwise send paymentMethod.id to your server 
		    //console.log("payment_method_id: " + result.paymentMethod.id);
		    var payParams = JSON.stringify({
			plan_id: this.plan_id,
			payment_method_id: result.paymentMethod.id
		    });
		    
		    axios.post(EMSStyleAPIBaseURL + 'contract', payParams, {
			headers: {
			    "Content-Type": "application/json"
			}
		    }).then((res) => {
				this.country = ""
				this.postal_code = ""
				loader.hide()
				// クーポンコード通知
				this.sendCoupon()
		    })
		    .catch((error) => {
			loader.hide()
			console.error(error)
			this.$swal({
				text: this.$t('general.errorMessage'),
				icon: 'error',
			});
		    });
		}
	    });
	},
		sendCoupon() {
			let loader = this.$loading.show();
			// クーポンコード通知
			var params = JSON.stringify({
				email: this.$store.state.loggedEmailID,
				plan_id: this.plan_id,
				lang: this.lang,
				invite_code: this.invite_code,
			});
			axios.post(EMSStyleAPIBaseURL2 + 'purchased', params, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then((res) => {
				loader.hide()
				this.$swal({
					html: this.$t('Payment.doneMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				}).then(() => {
					this.$router.push({
						name: 'Account',
					})
				})
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				// クーポンコード通知がエラーでも支払登録できているため正常処理
				this.$swal({
					html: this.$t('Payment.doneMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				}).then(() => {
					this.$router.push({
						name: 'Account',
					})
				})
			});
		},
    }
};
</script>

<style lang="scss" scoped>
.dis-log{
  margin-top: 0px;
  background: #f6f6f6;
}
.img-plane1{
  padding-top: 20px;
  max-width: 120px;
  margin-bottom: 20px;
}
.plan-name{
	font-weight:900;
}
.btn-primary {
  color: #fff;
  background-color: navy;
  border-color: #007bff;
}
.powered-by {
	font-size: 0.7rem;
	color: #b1b1b1;
}
.stripe {
	font-weight: bolder;
}
.payment-page {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
  #custom-button {
    background-color: #3d424e;
    padding: 5px;
    width: 100%;
    color: white;
  }
  #card-error {
    color: red;
  }
  .mb-10 {
    margin-bottom: 10px
  }
  .form-group {
    .form-control {
      padding: 10px 4px;
    }
    .select {
      padding: 0px;
    }
    .card-number-data {
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 0px;
      border-bottom: 0px;
    }
    .expiry-data {
      display: inline-block;
      width: 80%;
      border-radius: 0px 0 0 4px;
    }
    .cvv-data {
      display: inline-block;
      width: 20%;
      border-left: none;
      border-radius: 0px 0 4px 0px;
      padding-left: 6px
    }
  }
  .image-wrapper {
	  text-align: center;
    width: 200px;
    margin: auto;
    img {
      border-radius: 4px;
    }
  }
  .amount {
    color: #838383;
  }
}
</style>