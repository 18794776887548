import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeMobile from '../views/HomeMobile.vue'
import Book from '../views/Book.vue'
import BookMobile from '../views/BookMobile.vue'
import LessonDetail from '../views/LessonDetail.vue'
import LessonDetailMobile from '../views/LessonDetailMobile.vue'
import AddLesson from '../views/AddLesson.vue'
import AddLessonMobile from '../views/AddLessonMobile.vue'
import Login from '../views/Login.vue'
import Account from '../views/Account.vue'
import Plan from '../views/Plan.vue'
import Payment from '../views/Payment.vue'
import Registration from '../views/Registration.vue'
import Reset from '../views/Reset.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { isPublic: true }
  },
  {
    path: '/book',
    name: 'Book',
    component: Book,
  },
  {
    path: '/lesson-detail/:id',
    name: 'LessonDetail',
    component: LessonDetail,
    meta: { isPublic: true }
  },
  {
    path: '/book-detail/:id',
    name: 'LessonDetail',
    component: LessonDetail,
  },
  {
    path: '/add-lesson',
    name: 'AddLesson',
    component: AddLesson,
    meta: { isTrainer: true }
  },
  {
    path: '/edit-lesson/:id',
    name: 'AddLesson',
    component: AddLesson,
    meta: { isTrainer: true }
  },
  {
    path: '/mhome',
    name: 'HomeMobile',
    component: HomeMobile,
    meta: { isMobile: true }
  }, 
  {
    path: '/mbook',
    name: 'BookMobile',
    component: BookMobile,
    meta: { isMobile: true }
  },
  {
    path: '/mlesson-detail/:id',
    name: 'LessonDetailMobile',
    component: LessonDetailMobile,
    meta: { isMobile: true }
  },
  {
    path: '/mbook-detail/:id',
    name: 'LessonDetailMobile',
    component: LessonDetailMobile,
    meta: { isMobile: true }
  },
  {
    path: '/madd-lesson',
    name: 'AddLessonMobile',
    component: AddLessonMobile,
    meta: { isMobile: true }
  },
  {
    path: '/medit-lesson/:id',
    name: 'AddLesson',
    component: AddLessonMobile,
    meta: { isMobile: true }
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan,
    meta: { isPublic: true }
  },
  {
    path: '/login',
    name: 'Login', 
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { isPublic: true }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: { isPublic: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
  },
  {
    path: '/payment/:id',
    name: 'Payment',
    component: Payment,
  },
  //{
  //  path: '/logout',
  //  name: 'Logout',
  //  component: Logout
  //},
  /*{
    path: '/detail/:id',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {*/
      //return import(/* webpackChunkName: "about" */ '../views/Detail.vue')
    //}
  //}
]

const router = new VueRouter({
  routes
})

export default router
