<template> 
	<div class="d-flex">
		<div class="col py-1" v-bind:class="{ 'tab-active': active==1, 'tab-nonactive': active!=1 }">
			<router-link  :to="{ name: 'Home' }" class="d-block">
				<img v-if="active==1" src="assets/images/live-on.png" alt="" class="navimg"/>
				<img v-else src="assets/images/live-off.png" alt="" class="navimg"/>
				<div class="tab-text" v-bind:class="{ 'tab-text-active': active==1, 'tab-text-nonactive': active!=1 }">{{ $t('TabMenu.tab1') }}</div>
			</router-link>
		</div>
		<div v-if="isTrainer" class="col py-1" v-bind:class="{ 'tab-active': active==2, 'tab-nonactive': active!=2 }">
			<router-link :to="{ name: 'AddLesson' }" class="d-block">
				<img v-if="active==2" src="assets/images/create-on.png" alt="" class="navimg"/>
				<img v-else src="assets/images/create-off.png" alt="" class="navimg"/>
				<div class="tab-text" v-bind:class="{ 'tab-text-active': active==2, 'tab-text-nonactive': active!=2 }">{{ $t('TabMenu.tab2') }}</div>
			</router-link>
		</div>
		<div class="col py-1" v-bind:class="{ 'tab-active': active==3, 'tab-nonactive': active!=3 }">
			<router-link :to="{ name: 'Book' }" class="d-block">
				<img v-if="active==3" src="assets/images/booking-on.png" alt="" class="navimg"/>
				<img v-else src="assets/images/booking-off.png" alt="" class="navimg"/>
				<div class="tab-text" v-bind:class="{ 'tab-text-active': active==3, 'tab-text-nonactive': active!=3 }">{{ $t('TabMenu.tab3') }}</div>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TabMenu',
	props: ['active'],
	data() {
		return {
		}
	},
	computed: {
		isTrainer: function() {
			return this.$store.state.isTrainer;
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tab-nonactive {
	background: #f0f0f0;
	border-bottom: 2px solid #bbb;
	text-align: center;
}
.tab-active {
	background: white;
	border-bottom: 2px solid #ff5100;
	text-align: center;
}
.navimg {
	max-width: 60px;
}
.tab-text {
	font-size: 0.6rem;
}
a, a.active, a:hover {
	text-decoration: none;
}
.tab-nonactive a, .tab-nonactive a:active, .tab-nonactive a:hover {
	color: #bbb;
}
.tab-active a, .tab-active a:active, .tab-active a:hover {
	color: #000;
}
@media screen and (min-width:992px) {
	.navimg {
		max-width: 80px;
	}
}
</style>