<template> 
	<div class="container-fluid p-0">
		<LessonDetailView :lesson="lesson"></LessonDetailView>
		<div v-if="!isTrainer" class="my-4 text-center">
			<div v-if="isTrainingFinished" class="text-center">
				{{ $t('LessonDetail.finished') }}
			</div>
			<div v-if="isReserved">
				<b-button
					v-if="isTrainingTime && isMobile"
					squared
					variant="warning"
					class="main-button"
					@click="redirectToLessonStart">
					{{ $t('LessonDetail.start') }}
				</b-button>
				<b-button
					v-if="isCancelableTime"
					squared
					class="gray-button"
					@click="cancelReservation">
					{{ $t('LessonDetail.cancel') }}
				</b-button>
				<div v-if="!isCancelableTime && !isTrainingTime && !isTrainingFinished" class="text-center">
					{{ $t('LessonDetail.waiting') }}
				</div>
				<div v-if="isTrainingTime && !isMobile" class="text-center">
					{{ $t('LessonDetail.toApp') }}
				</div>
			</div>
			<div v-if="!isReserved && remainNumber>0 && isCancelableTime">
				<b-button
					v-if="loggedIn && loggedUserTicketRemaining>=lesson.ticket"
					squared
					variant="warning"
					class="main-button"
					@click="reserveLesson">
					{{ $t('LessonDetail.reserve') }}
				</b-button>
				<b-button
					v-if="loggedIn && loggedUserTicketRemaining<lesson.ticket && !isMobile"
					squared
					variant="warning"
					class="main-button"
					@click="redirectToPlan">
					{{ $t('LessonDetail.reserve') }}
				</b-button>
				<b-button
					v-if="!loggedIn && (!isMobile || setFromMobile)"
					squared
					variant="warning"
					class="main-button"
					@click="redirectToLogin">
					{{ $t('LessonDetail.login') }}
				</b-button>
			</div>
		</div>
		<div v-if="isMyLesson" class="wht p-1 mt-4">
			<div class="training-desc font-weight-bold">{{ $t('LessonDetail.members') }}</div>
			<div class="member-list pl-2" v-for="(nickname, index) in lesson.nickname_list" :key="index">
				{{ nickname }}
    		</div>
			<div class="my-4 text-center">
				<b-button
					v-if="isTrainingTime && isMobile && lesson!=null && lesson.reservation_count>0"
					squared
					variant="warning"
					class="main-button"
					@click="redirectToLessonStart">
					{{ $t('LessonDetail.start') }}
				</b-button>
				<div v-if="lesson!=null && lesson.reservation_count===0">
					<b-button
						squared
						class="gray-button"
						@click="cancelLesson">
						{{ $t('LessonDetail.delete') }}
					</b-button>
					<b-button
						squared
						variant="warning"
						class="yellow-button ml-4"
						@click="redirectToEditLesson">
						{{ $t('LessonDetail.edit') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import moment from "moment"
import LessonDetailView from '@/components/LessonDetailView.vue'

export default {
	name: 'LessonDetailContent',
	props: ['lessonId', 'isMobile'],
	components: {
		LessonDetailView,
	},
	data() {
	    return {
			lesson: {},
			isReserved: false,
			isCancelableTime: false,
			isTrainingTime: false,
			isTrainingFinished: false,
			timerID: null,
			loggedUserTicketRemaining: 0,
	    }
	},
	computed: {
		loggedIn: function() {
			return this.$store.state.loggedIn;
		},
		isTrainer: function() {
			return this.$store.state.isTrainer;
		},
		loggedUserPlan: function() {
			return this.$store.state.loggedUserPlan;
		},
		loggedUserID: function() {
			return this.$store.state.loggedUserID;
		},
		setFromMobile: function() {
			return this.$store.state.setFromMobile;
		},
		isMyLesson: function() {
			if (!this.isTrainer) {
				return false;
			} else if (this.lesson.user_id == null) {
				return false;
			} else {
				return this.lesson.user_id === this.loggedUserID;
			}
		},
		remainNumber: function() {
			if (this.lesson.max_member == null && this.lesson.reservation_count == null) {
				return 0;
			} else if (this.lesson.reservation_count == null) {
				return this.lesson.max_member;
			} else {
				return this.lesson.max_member - this.lesson.reservation_count;
			}
		}
	},
	mounted() {
		this.timerID = setInterval(this.init, 5*60*1000);
		this.init();
	},
	beforeDestroy() {
		if (this.timerID != null) {
			clearInterval(this.timerID);
		}
	},
	watch: {
		setFromMobile: function (val) {
			if (val && this.isMobile) {
				if (this.loggedIn && !this.isTrainer) {
					this.getUserTicketRemaining();
					this.checkReservation();
				}
				this.checkTime();
			}
		}
	},
	methods: {
		init() {
			this.getLesson();
			if (this.loggedIn && !this.isTrainer) {
				this.getUserTicketRemaining();
				this.checkReservation();
			}
		},
		getLesson() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'lesson/' + this.lessonId)
			.then(response => {
				this.lesson = response.data
				this.lesson.from_date = this.lesson.from_date + "Z";
				this.lesson.to_date = this.lesson.to_date + "Z";
				this.checkTime();
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				this.$swal({
					text: this.$t('general.errorMessage'),
					icon: 'error',
				});
			});
		},
		getUserTicketRemaining() {
			let loader = this.$loading.show()
			axios.get(EMSStyleAPIBaseURL + 'ticket')
			.then(response => {
				this.loggedUserTicketRemaining = response.data.available
				loader.hide()
			})
			.catch((error) => {
				loader.hide()
				console.error(error)
				this.loggedUserTicketRemaining = 0;
			});
		},
		checkReservation() {
			axios.get(EMSStyleAPIBaseURL + 'reservation/' + this.lessonId)
			.then(response => {
				this.isReserved = true;
			})
			.catch((error) => {
				this.isReserved = false;
			});
		},
		checkTime() {
			let now = moment();
			if (this.lesson.same_day_reservation_flg) {
				// 当日予約可の場合はレッスン開始日時から30分前がキャンセル締め切り日時
				this.isCancelableTime = moment(this.lesson.from_date).subtract(30, 'minutes').isAfter(now);
			} else {
				// 当日予約不可の場合はレッスン開始日時から24時間前がキャンセル締め切り日時
				this.isCancelableTime = moment(this.lesson.from_date).subtract(1, 'days').isAfter(now);
			}
			if (this.isTrainer) {
				// トレーナーはレッスン開始10分前からレッスン終了予定時刻まで入室可能
				this.isTrainingTime = moment(this.lesson.from_date).subtract(10, 'minutes').isSameOrBefore(now) && moment(this.lesson.to_date).isAfter(now);
			} else {
				// トレイニーはレッスン開始5分前からレッスン終了予定時刻まで入室可能
				this.isTrainingTime = moment(this.lesson.from_date).subtract(5, 'minutes').isSameOrBefore(now) && moment(this.lesson.to_date).isAfter(now);
			}
			// for test
			//this.isTrainingTime = moment(this.lesson.from_date).subtract(1, 'days').isSameOrBefore(now) && moment(this.lesson.to_date).isAfter(now);
			if (moment(this.lesson.to_date).isBefore(now)) {
				this.isTrainingFinished = true;
				if (this.timerID != null) {
					clearInterval(this.timerID);
				}
			}
		},
	    reserveLesson: function (e) {
			let loader = this.$loading.show()
			var formData = JSON.stringify({
			    lesson_id: this.lessonId,
			});
			axios.post(EMSStyleAPIBaseURL + 'reservation', formData, {
			    headers: {
				"Content-Type": "application/json"
			    }
			})
			.then(response => {
			    //console.log(response.data)
			    loader.hide()
				this.init();
				this.$swal({
					text: this.$t('LessonDetail.doneReserveMessage'),
					iconColor: 'transparent',
					iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
				});
			})
			.catch((error) => {
			    loader.hide()
			    console.error(error)
				this.$swal({
					text: this.$t('LessonDetail.errorReserveMessage'),
					icon: 'error',
				});
			});
	    },
		cancelReservation() {
			this.$swal({
				text: this.$t('LessonDetail.confirmationCancelMessage'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK'
			}).then((result) => {
				if (result.value) {
					let loader = this.$loading.show()
					axios.delete(EMSStyleAPIBaseURL + 'reservation/' + this.lessonId)
					.then(response => {
						loader.hide()
						this.init();
						this.$swal({
							text: this.$t('LessonDetail.doneCancelMessage'),
							iconColor: 'transparent',
							iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
						});
					})
					.catch((error) => {
						loader.hide()
						console.error(error)
						this.$swal({
							text: this.$t('LessonDetail.errorCancelMessage'),
							icon: 'error',
						});
					});
				}
			})
			.catch(err => {
				// An error occurred
				loader.hide()
				console.error(err)
			})
	    },
		cancelLesson() {
			this.$swal({
				text: this.$t('LessonDetail.confirmationDeleteMessage'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK'
			}).then((result) => {
				if (result.value) {
					let loader = this.$loading.show()
					axios.delete(EMSStyleAPIBaseURL + 'lesson/' + this.lessonId)
					.then(response => {
						loader.hide()
						this.redirectToBook()
						this.$swal({
							text: this.$t('LessonDetail.doneDeleteMessage'),
							iconColor: 'transparent',
							iconHtml: '<img src="assets/images/check_ok.png" width="88" height="88">',
						});
					})
					.catch((error) => {
						loader.hide()
						console.error(error)
						this.init()
						this.$swal({
							text: this.$t('LessonDetail.errorDeleteMessage'),
							icon: 'error',
						});
					});
				}
			})
			.catch(err => {
				// An error occurred
				loader.hide()
				console.error(err)
			})
		},
		redirectToLessonStart() {
			if (this.isMobile) {
				this.$emit('redirectToLessonStart', JSON.stringify(this.lesson));
			}
		},
		redirectToLogin() {
			this.$emit('redirectToLogin');
		},
		redirectToPlan() {
			this.$emit('redirectToPlan');
		},
		redirectToBook() {
			this.$emit('redirectToBook');
		},
		redirectToEditLesson() {
			this.$emit('redirectToEditLesson');
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wht{
	background: white;
}
.training-desc {
	font-size: 0.8rem;
}
.member-list {
	font-size: 0.7rem;
}
</style>
