<template> 
	<div>
		<HeaderPostLogin v-if="loggedIn"></HeaderPostLogin>	    
		<HeaderContent v-else></HeaderContent>
		<TabMenu v-if="loggedIn" :active="1"></TabMenu>
		<!-- Main content Start -->
		<div class="container-fluid mt-3">
			<div class="row flex-row flex-nowrap overflow-auto pr-2" v-for="n in rows">
				<div v-if="n==1&&loggedUserPlan==''" class="col-11 col-md-5 col-xl-3 mb-3 pl-2 pr-0">
					<banner-plans></banner-plans>
				</div>
				<div class="col-11 col-md-5 col-xl-3 mb-3 pl-2 pr-0" :key="index" v-for="(item, index) in sliceList(n)">
					<lesson-card :item="item" @click="redirectToLessonDetail"></lesson-card>
				</div>
			</div>
		</div>
		<!-- Main content End -->
		<FooterContent></FooterContent>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import moment from "moment"
import HeaderContent from '@/views/HeaderContent.vue'
import HeaderPostLogin from '@/views/HeaderPostLogin.vue'
import FooterContent from '@/views/FooterContent.vue'
import TabMenu from '@/components/TabMenu.vue'
import LessonCard from '@/components/LessonCard.vue'
import BannerPlans from '@/components/BannerPlans.vue'

export default {
	components: {
		HeaderContent,
		HeaderPostLogin,
		FooterContent,
		TabMenu,
		LessonCard,
		BannerPlans,
	},
	data() {
		return {
			lessonList: [],
			perRow: 4,
		}
	},
	computed: {
		loggedIn: function() {
			return this.$store.state.loggedIn;
		},
		loggedUserPlan: function() {
			return this.$store.state.loggedUserPlan;
		},
		rows: function() {
			if (this.loggedUserPlan == '') {
				return Math.ceil((this.lessonList.length + 1) / this.perRow)
			} else {
				return Math.ceil(this.lessonList.length / this.perRow)
			}
		}
	},
	mounted() {
		document.title = 'Home'
		let loader = this.$loading.show()
		this.$store.commit('setBackTo', 'Home');
		axios.get(EMSStyleAPIBaseURL + 'lesson')
		.then(response => {
			let now = moment();
			this.lessonList = response.data
			.filter(item => {
				if (item.same_day_reservation_flg) {
					// 当日予約可の場合はレッスン開始日時から30分前が予約締め切り日時
					return moment(item.from_date + "Z").subtract(30, 'minutes').isAfter(now);
				} else {
					// 当日予約不可の場合はレッスン開始日時から24時間前が予約締め切り日時
					return moment(item.from_date + "Z").subtract(1, 'days').isAfter(now);
				}
			})
			.sort((a, b) => {
				//from_dateの昇順
				if(a.from_date > b.from_date) return 1;
				if(a.from_date < b.from_date) return -1;
				return 0;
			});
			this.lessonList.forEach(item => {
				item.from_date = item.from_date + "Z";
				item.to_date = item.to_date + "Z";
			});
			loader.hide()
		})
		.catch((error) => {
			loader.hide()
			console.error(error)
			this.$swal({
				text: this.$t('general.errorMessage'),
				icon: 'error',
			});
		});
	},
	methods: {
		sliceList(n) {
			if (this.loggedUserPlan == '') {
				if (n == 1) {
					return this.lessonList.slice(0, 3);
				} else {
					return this.lessonList.slice((n-1)*this.perRow - 1, n*this.perRow - 1);
				}
			} else {
				return this.lessonList.slice((n-1)*this.perRow, n*this.perRow);
			}
		},
		redirectToLessonDetail(lessonId) {
			this.$router.push(`/lesson-detail/${lessonId}`);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>