<template> 
	<!-- Main content Start -->
	<div class="main">
	<div v-if="rows!=null&&rows>0" class="container-fluid pt-3">
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4 mb-3" :key="index" v-for="(item, index) in showList">
				<BookCard :item="item" @click="redirectToBookDetail"></BookCard>
			</div>
		</div>
		<b-pagination
			v-model="currentPage"
			:total-rows="rows"
			:per-page="perPage"
			align="center"
			@change="pageChange"
		>
			<template #first-text><img src="assets/images/prev_all.png" alt="First page" width="36" height="36"></template>
			<template #prev-text><img src="assets/images/prev_page.png" alt="Previous page" width="36" height="36"></template>
			<template #next-text><img src="assets/images/next_page.png" alt="Next page" width="36" height="36"></template>
			<template #last-text><img src="assets/images/next_all.png" alt="Last page" width="36" height="36"></template>
			<template #page="{ page }"><span class="page-number">{{ page }}</span></template>
			<template #ellipsis-text><span class="page-ellipsis">…</span></template>
		</b-pagination>
	</div>
	<div v-if="rows!=null&&rows==0" class="text-center py-3">
		<div v-if="isTrainer">{{ $t('Book.noListTrainer') }}</div>
		<div v-else>{{ $t('Book.noListTrainee') }}</div>
	</div>
	</div>
	<!-- Main content End -->
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import moment from "moment"
import BookCard from '@/components/BookCard.vue'

export default {
	components: {
		BookCard,
	},
	data() {
		return {
			bookList: [],
			showList: [],
			currentPage: this.$store.state.bookCurrentPage,
			rows: null,
			perPage: 4,
		}
	},
	computed: {
		isTrainer: function() {
			return this.$store.state.isTrainer;
		},
	},
	mounted() {
		window.getSessionFromMobile = this.getSessionFromMobile;
		document.title = 'Book';
		let loader = this.$loading.show();
		this.$store.commit('setBackTo', 'BookMobile');
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		axios.get(EMSStyleAPIBaseURL + 'lesson/reserved-or-self-added')
		.then(response => {
			let now = moment();
			this.bookList = response.data.sort((a, b) => {
				if (moment(a.to_date + "Z").isAfter(now) && moment(b.to_date + "Z").isAfter(now)) {
					//未来はto_dateの昇順
					if(a.to_date > b.to_date) return 1;
					if(a.to_date < b.to_date) return -1;
					return 0;
				} else {
					//to_dateの降順
					if(a.to_date > b.to_date) return -1;
					if(a.to_date < b.to_date) return 1;
					return 0;
				}
			});
			this.bookList.forEach(item => {
				item.from_date = item.from_date + "Z";
				item.to_date = item.to_date + "Z";
			});
			this.rows = response.data.length;
			this.pageChange();
			loader.hide();
		})
		.catch((error) => {
			loader.hide()
			console.error(error)
			this.$swal({
				text: this.$t('general.errorMessage'),
				icon: 'error',
			});
		});
	},
	methods: {
		// 画面幅から1ページ表示数判定
		handleResize: function() {
			if (window.innerWidth < 768) {
				this.perPage = 4;
			} else if (window.innerWidth < 992) {
				this.perPage = 8;
			} else {
				this.perPage = 12;
			}
		},
		pageChange() {
			this.$nextTick(function () {
				this.showList = this.bookList.slice((this.currentPage-1)*this.perPage, this.currentPage*this.perPage);
				this.$store.commit('setBookCurrentPage', this.currentPage);
			})
		},
		redirectToBookDetail(lessonId) {
			this.$router.push(`/mlesson-detail/${lessonId}`);
		},
		getSessionFromMobile(loggedSessionID, isTrainer, loggedUserPlan, loggedUserID) {
			this.$store.commit("set", {
				loggedSessionID: loggedSessionID,
				isTrainer: isTrainer,
				loggedUserPlan: loggedUserPlan,
				loggedUserID: loggedUserID,
			});
		}
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleResize);
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  min-height: 100vh;
}
ul {
	margin-bottom: 0;
	padding-bottom: 1rem;
}
</style>